import React from "react";
import {Table, Tooltip, Tag} from 'antd';
import '../../scss/mixin.css'
import request from '../../utils/request';
import GlobalParameters from '../../utils/globalParameters';
import './AuthorisedOfficer.css'
import {connect} from "react-redux";


class AuthorisedOfficer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manSolAssignedTeam: [],
        };
    };

    componentDidMount() {
        let myInterval = setInterval(()=>{
            if(sessionStorage.getItem('customer_id') ){
                let customer_id=sessionStorage.getItem('customer_id');
                this.props.dispatch({type:'AUTHOFFICER_REQUEST',customer_id});
                clearInterval(myInterval);
            }
        }, 20);

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(
            nextProps.manSolAssignedTeamDataSource &&
            (this.props.manSolAssignedTeamDataSource !== nextProps.manSolAssignedTeamDataSource)
        ) {
            const colData = [];
            const manSolTeam = nextProps.headerMansolAssignedTeam;

            if(manSolTeam && manSolTeam.length) {
                manSolTeam.map((item, i) => {

                return (
                  <span key={i}>
                    {colData.push({
                      title: (<strong>{item}</strong>),
                      dataIndex: item,
                      render:(text, record) => {
                        return (
                            <div key={item}>
                                {text}
                            </div>
                        )
                      }
                    })}
                  </span>
                )
              })
            }
            this.setState({ manSolAssignedTeam: colData });
        }
        return true;
    }

    render() {

        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            width:'12%',
            sorter: true,
            key: 'name',
            render: (_, record) => {
                if (record.cportal_enabled) {
                    return (
                        <Tag color="#3EA3FF">{record.name}</Tag>
                      );
                }  else {
                    return (
                        <Tag>{record.name}</Tag>
                      );
                }
            }
             },
            {
                title: "Site",
                dataIndex: 'site',
                key: 'site',
            },
            {
            title: <Tooltip title="Key person to administer contacts"><span style={{cursor: 'pointer',color: '#337ab7',wordBreak:'keep-all',wordWrap:'break-word'}}>Primary Contact</span></Tooltip>,
            dataIndex: 'p1',
            key: 'p1',
            render: (text) => {
                if(text==0){
                    return <span className="glyphicon glyphicon-ok"></span>
                }
            },
        },
            {
            title: <Tooltip title="The person we will communicate with"><span style={{cursor: 'pointer',color: '#337ab7',wordBreak:'keep-all',wordWrap:'break-word'}}>Liaison Contact</span></Tooltip>,
            dataIndex: 'p2',
            key: 'p2',
            render: (text) => {
                if(text==0){
                    return <span className="glyphicon glyphicon-ok"></span>
                }
            },
        }, {
            title:  <Tooltip title="Receive outage alerts"><span style={{cursor: 'pointer',color: '#337ab7',wordBreak:'keep-all',wordWrap:'break-word'}}>Alerts Contact</span></Tooltip>,
            dataIndex: 'p3',
            key: 'p3',
            render: (text) => {
                if(text==0){
                    return <span className="glyphicon glyphicon-ok"></span>
                }
            },
        }, {
            title: <Tooltip title="Receive invoices and billing enquiries"><span style={{cursor: 'pointer',color: '#337ab7',wordBreak:'keep-all',wordWrap:'break-word'}}>Invoice Contact</span></Tooltip>,
            dataIndex: 'p4',
            key: 'p4',
            render: (text) => {
                if(text==0){
                    return <span className="glyphicon glyphicon-ok"></span>
                }
            },
        }, {
            title: <Tooltip title="Can ask for quotations for services or goods"><span style={{cursor: 'pointer',color: '#337ab7',wordBreak:'keep-all',wordWrap:'break-word'}}>Ask for Quotes</span></Tooltip>,
            dataIndex: 'p5',
            key: 'p5',
            render: (text) => {
                if(text==0){
                    return <span className="glyphicon glyphicon-ok"></span>
                }
            },
        }, {
            title: <Tooltip title="Able to purchase services/products"><span style={{cursor: 'pointer',color: '#337ab7',wordBreak:'keep-all',wordWrap:'break-word'}}>Purchase Services/Products</span></Tooltip>,
            dataIndex: 'p6',
            key: 'p6',
            render: (text) => {
                if(text==0){
                    return <span className="glyphicon glyphicon-ok"></span>
                }
            },
        }, {
            title: <Tooltip title="Able to request on-site service"><span style={{cursor: 'pointer',color: '#337ab7',wordBreak:'keep-all',wordWrap:'break-word'}}>Tech Support</span></Tooltip>,
            dataIndex: 'p7',
            key: 'p7',
            render: (text) => {
                if(text==0){
                    return <span className="glyphicon glyphicon-ok"></span>
                }
            },
        }, {
            title: <Tooltip title="Able to request remote help desk support"><span style={{cursor: 'pointer',color: '#337ab7',wordBreak:'break-word'}}>Help Desk Support</span></Tooltip>,
            dataIndex: 'p8',
            key: 'p8',
            render: (text) => {
                if(text==0){
                    return <span className="glyphicon glyphicon-ok"></span>
                }
            },
        }, {
            title: <Tooltip title="Receive copies of job sheets for completed work"><span style={{cursor: 'pointer',color: '#337ab7',wordBreak:'keep-all',wordWrap:'break-word'}}>Job Sheet Contact</span></Tooltip>,
            dataIndex: 'p9',
            key: 'p9',
            render: (text) => {
                if(text==0){
                    return <span className="glyphicon glyphicon-ok"></span>
                }
            },
        }
        ];

        return (
            <div>
                <div className="marginTop">
                </div>
                < div
                    className="container">
                    < div
                        className="panel panel-success">
                        < div
                            className="panel-heading">
                            < h3 className="panel-title"> Authorised Officers (Hover your mouse over the title for further explanation)</h3>
                        </div>
                        <div className="panel-body">
                            <Table columns={columns} size={'small'}
                                pagination={false}
                                onChange={(pagination, filters, sorter)=>{
                                    if (sorter.field && sorter.order) {
                                        this.props.dispatch({type:'TICKETS_TABLE_SORT',
                                            payload: {
                                                sortKey: sorter.field,
                                                sortOrder: sorter.order,
                                                type:7,
                                                uniqueDataSource:this.props.backupAuthOfficerDataSource
                                            }
                                        });

                                    }
                                }}
                                dataSource={this.props.authOfficerDataSource}
                                rowKey={record => '' + record.id}
                            />
                            <div className="marginTop">
                                Users highlighted in <b style={{color:'#3EA3FF'}}>blue</b> have access to the client portal.
                            </div>
                            <div className="marginTop">
                                The above table shows the authorised officers that we have recorded for your company. If the details are incorrect, please download the Client Authority Form and complete the new details to add a person or change their role and then forward the request to us, or simply email us at
                                <a style={{cursor: 'pointer',marginLeft:'2px'}}>support@mansol.net.au </a>to have a name removed.
                            </div>
                            <div className="marginTop pull-right">
                                <a className="authorisedStyle" href={`${GlobalParameters.website}/cportal/images/Client%20Authority%20Form.pdf`} download="ClientAuthorityForm.pdf">Download Client Authority Form</a>
                            </div>
                        </div>
                    </div>
                </div>
                < div
                    className="container">
                    < div
                        className="panel panel-success">
                        < div
                            className="panel-heading">
                            < h3 className="panel-title">Managed Solutions Assigned Team</h3>
                        </div>
                        <div className="panel-body">
                            <Table
                                columns={this.state.manSolAssignedTeam}
                                size={'small'}
                                pagination={false}
                                dataSource={this.props.manSolAssignedTeamDataSource}
                                rowKey={record => '' + record.id}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}



const mapStateToProps = state => {
    return {
        backupAuthOfficerDataSource: state.ticketsQuotesState.backupAuthOfficerDataSource,
        authOfficerDataSource: state.ticketsQuotesState.authOfficerDataSource,
        manSolAssignedTeamDataSource: state.ticketsQuotesState.manSolAssignedTeamDataSource,
        headerMansolAssignedTeam: state.ticketsQuotesState.headerMansolAssignedTeam,

    };}

export default connect(mapStateToProps)(AuthorisedOfficer);
