import React from 'react'

import TicketQuotes from './TicketQuotes'

const AppHomePage = ({ history }) => (
    <div>
        <TicketQuotes/>
    </div>
)

export default AppHomePage