const initialState = {
    securityQuestions: [],
}

const securityReducer = (state = initialState, action) => {
    if (action.type === 'SECURITY_QUESTIONS_QUERRY') {
        let securityQuestions = [];
        return {...state, securityQuestions}
    }
    return state
}

export default securityReducer