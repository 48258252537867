import React from "react";
import {Select, Input, Button, message} from 'antd';
import request from '../../utils/request';
import GlobalParameters from '../../utils/globalParameters';
import {connect} from "react-redux";
import '../../scss/mixin.css'
import queryString from "query-string";

const Option = Select.Option;
let token='';
class UserRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            securityQuestionsDataSource: [],
            question1: null,
            question2: null,
            question3: null,
            question1Value:null,
            question2Value:null,
            question3Value:null,
            answer1: null,
            answer2: null,
            answer3: null,
        };
    };


    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        token = values.token;

        request(`${GlobalParameters.website}/erams/api/security/list`, {method: 'put'}, token, 3).then((response) => {
            let resData = response.data[1];
            resData = resData[0].data;
            let securityQuestionsDataSource = [];
            for (var i = 0; i < resData.length; i++) {
                var temp = new Object();
                temp.id = ''+resData[i].id;
                temp.question = resData[i].attributes.question;
                temp.answer = resData[i].attributes.answer;
                securityQuestionsDataSource.push(temp);

            }
            this.setState({
                securityQuestionsDataSource
                , question1: securityQuestionsDataSource[0].id
                , question2: securityQuestionsDataSource[1].id
                , question3: securityQuestionsDataSource[2].id
                ,question1Value: securityQuestionsDataSource[0].question
                ,question2Value: securityQuestionsDataSource[1].question
                ,question3Value: securityQuestionsDataSource[2].question
            })
        });
    }


    render() {
        let securityQuestionsOptions = this.state.securityQuestionsDataSource.map(d => <Option key={'' + d.id}
                                                                                               title={'' + d.question}
                                                                                               value={'' + d.id}>{d.question}</Option>);
        return (
            <div>
                <div className="marginTop">
                </div>
                < div
                    className="container">

                    < div
                        className="panel panel-success">
                        < div
                            className="panel-heading">
                            < h3 className="panel-title"> Setting Security Questions </h3>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <p> Thank you for taking the time to register for access to the customer portal.
                                        You will be asked to add 3 security questions with their corresponding answers.
                                        These will help to secure your access and allow us to use these questions when you call our support phone number.
                                        Once you have selected these 3 questions you will be prompted to choose a password</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <span style={{marginRight: 3}}>Question 1  : </span>
                                    <Select
                                        showSearch
                                        style={{width: '88%'}}
                                        value={this.state.question1}
                                        onChange={(value,option) => {
                                            this.setState({question1: value,question1Value:option.props.title});
                                        }}
                                        filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {securityQuestionsOptions}
                                    </Select>

                                </div>
                            </div>
                            <div className="marginTop5"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Input placeholder="" style={{width: '99%'}} onChange={(e) => {
                                        this.setState({answer1: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="marginTop10"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <span style={{marginRight: 3}}>Question 2  : </span>
                                    <Select
                                        showSearch
                                        style={{width: '88%'}}
                                        value={this.state.question2}
                                        onChange={(value,option) => {
                                            this.setState({question2: value,question2Value:option.props.title});
                                        }}
                                        filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {securityQuestionsOptions}
                                    </Select>
                                </div>
                            </div>
                            <div className="marginTop5"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Input placeholder="" style={{width: '99%'}} onChange={(e) => {
                                        this.setState({answer2: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="marginTop10"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <span style={{marginRight: 3}}>Question 3  : </span>
                                    <Select
                                        showSearch
                                        style={{width: '88%'}}
                                        value={this.state.question3}
                                        onChange={(value,option) => {
                                            this.setState({question3: value,question3Value:option.props.title});
                                        }}
                                        filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {securityQuestionsOptions}
                                    </Select>
                                </div>
                            </div>

                            <div className="marginTop5"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Input placeholder="" style={{width: '99%'}}  onChange={(e) => {
                                        this.setState({answer3: e.target.value})
                                    }}/>
                                </div>
                            </div>

                            <div className="marginTop10"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Button type="primary" onClick={() => {
                                        if (this.state.question1 == this.state.question2 || this.state.question2 == this.state.question3 || this.state.question3 == this.state.question1) {
                                            message.error('Repeating questions');
                                            return;
                                        }
                                        if (this.state.answer1 == this.state.answer2 || this.state.answer2 == this.state.answer3 || this.state.answer3 == this.state.answer1) {
                                            message.error('Repeating answers');
                                            return;
                                        }
                                        const values = {};
                                        values.question = [this.state.question1Value, this.state.question2Value, this.state.question3Value];
                                        values.answer = [this.state.answer1, this.state.answer2, this.state.answer3];

                                        request(`${GlobalParameters.website}/erams/api/security/answer`, {method: 'put',data:values}, token, 3).then((response) => {
                                            if (response.data[0] === 'exception') {
                                                message.error(response.data[1])

                                            }else{
                                                this.props.history.push({
                                                    pathname: '/auth/setNewPassword',
                                                    search: `?token=${response.data[1]}`
                                                })
                                                // $location.path('/setNewPassword/' + result.data[1]);
                                            }
                                        });
                                    }}>Submit</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        clientId: state.loggedUserState.clientId,
        token: state.loggedUserState.token
    }
}
export default connect(mapStateToProps)(UserRegistration);
