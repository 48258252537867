import React from 'react'
import './NavHead.css'
import {Link, Redirect, withRouter} from 'react-router-dom'
import {NavLink} from 'react-router-dom'
import {Tabs, Select,} from 'antd';
import request from "../../utils/request";
import GlobalParameters from "../../utils/globalParameters";

import {connect} from "react-redux";

const Option = Select.Option;

const TabPane = Tabs.TabPane;
const children = [];
for (let i = 10; i < 36; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

function handleChange(value) {
}

class NavHead extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customers: [],
            customerId: '',
            name: '',
            visible: false,
        }
    }

    componentDidMount() {
        let clientId = sessionStorage.getItem("clientId");
        let token = sessionStorage.getItem("token");

        request(`${GlobalParameters.website}/erams/api/cportal/contacts/` + clientId, {method: 'get'}, token).then(({data}) => {
            let customers = [];
            for (var i = 0; i < data.customers.length; i++) {
                let item = {};
                item.id = '' + data.customers[i].id;
                item.is_authorized = data.customers[i].is_authorized;
                if (data.customers[i].business == true) {
                    item.name = data.customers[i].name;
                } else {
                if (data.customers[i].first_name) {
                        item.name = data.customers[i].first_name + " " + data.customers[i].name;
                    } else {
                        item.name = data.customers[i].name;
                    }
                }
                customers.push(item);
            }
            let name = data.name;
            let customerId = customers[0].id;
            sessionStorage.setItem("customer_id", customerId);
            this.setState({customers, name, customerId}, () => {
                const clientDetails = this.state.customers[0];
                this.props.dispatch({type: 'GET_SELECTED_CLIENT', clientDetails});
            })

        }).catch((err) => {
            sessionStorage.clear();
            window.location.href = `${GlobalParameters.website}/auth`;
        });;
    }

    render() {
        let customersOptions = this.state.customers.map(d => <Option key={'' + d.id}
                                                                     value={'' + d.id}
                                                                     title={d.name}>{d.name}</Option>);
        let headersecond = <div>
            <div onClick={() => {
                this.setState({visible: false})
            }}>
                <Link to="/client"><span className="glyphicon glyphicon-home"></span>&nbsp;&nbsp;Tickets &
                    Quotes</Link>
                <Link to="/client/forms"><span
                    className="glyphicon glyphicon-book"></span>&nbsp;&nbsp;Forms</Link>
                <Link to="/client/authOfficer"><span
                    className="glyphicon glyphicon-user"></span>&nbsp;&nbsp;Authorised Officers</Link>


                <Link to="/client/secureReset"><span className="glyphicon glyphicon-wrench"></span>&nbsp;&nbsp;Security
                    Questions and Verbal Password</Link>
                <Link to="/client/resetPassword"><span className="glyphicon glyphicon-user"></span>&nbsp;&nbsp;Change
                    Password
                </Link>


                <a onClick={() => {
                    sessionStorage.clear();
                    window.location.reload();
                    window.location.href = `${GlobalParameters.website}/auth`;
                }}><span className="glyphicon glyphicon-off"></span>&nbsp;&nbsp;Logout</a>
            </div>
            <Select
                size={'small'}
                style={{minWidth: 160, width: '90%'}}
                value={'' + this.state.customerId}
                onChange={(value, option) => {
                    this.setState({customerId: '' + value, visible: false})
                    let customer_id = value;
                    sessionStorage.setItem("customer_id", customer_id);
                    if (customer_id) {
                        const clientDetails = this.state.customers.filter(el => parseInt(el.id) === parseInt(value))[0];
                        this.props.dispatch({type: 'GET_SELECTED_CLIENT', clientDetails});
                        this.props.dispatch({type: 'TICKETSQUOTES_REQUEST', customer_id});
                        this.props.dispatch({type: 'OPEN_TICKETSQUOTES_REQUEST', customer_id});
                        this.props.dispatch({type: 'CLOSE_TICKETSQUOTES_REQUEST', customer_id});
                        this.props.dispatch({type: 'OPEN_QUOTES_REQUEST', customer_id});
                        this.props.dispatch({type: 'ACCEPTED_QUOTES_REQUEST', customer_id});
                        this.props.dispatch({type: 'CLOSED_QUOTES_REQUEST', customer_id});
                        this.props.dispatch({type: 'USERS_REQUEST', customer_id});
                        this.props.dispatch({type: 'AUTHOFFICER_REQUEST', customer_id});
                    }
                }}
                filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {customersOptions}
            </Select>
            <span className="userWelcomeName">{this.state.name}</span>
        </div>;
        let header = <div className="NavHeadMenu">
            <div className="LeftNavHeadMenu">
                <Link to="/client"><span className="glyphicon glyphicon-home"></span>&nbsp;&nbsp;Tickets &
                    Quotes</Link>
                <Link to="/client/forms"><span
                    className="glyphicon glyphicon-book"></span>&nbsp;&nbsp;Forms</Link>
                <Link to="/client/authOfficer"><span
                    className="glyphicon glyphicon-user"></span>&nbsp;&nbsp;People</Link>
                <span className="dropdown"><a><span
                    className="glyphicon glyphicon-cog"></span>&nbsp;Security and Authentication<span
                    className="caret"></span></a>
                                 <div className="dropdown-content">
                                     <span className="dropdown-content-top"></span>
<Link to="/client/secureReset"><span className="glyphicon glyphicon-wrench"></span>&nbsp;&nbsp;Security Questions and Verbal Password</Link>
<Link to="/client/resetPassword"><span className="glyphicon glyphicon-user"></span>&nbsp;&nbsp;Change Password
                            </Link>
  </div>
                                </span>
                <a href="https://mansol.net.au/contact/" target="_blank">Contact Us</a>
            </div>
            <div className="RightNavHeadMenu">
                <Select
                    size={'small'}
                    value={'' + this.state.customerId}
                    onChange={(value, option) => {
                        this.setState({customerId: '' + value})
                        let customer_id = value;
                        sessionStorage.setItem("customer_id", customer_id);
                        if (customer_id) {
                            const clientDetails = this.state.customers.filter(el => parseInt(el.id) === parseInt(value))[0];
                            this.props.dispatch({type: 'GET_SELECTED_CLIENT', clientDetails});
                            this.props.dispatch({type: 'TICKETSQUOTES_REQUEST', customer_id});
                            this.props.dispatch({type: 'OPEN_TICKETSQUOTES_REQUEST', customer_id});
                            this.props.dispatch({type: 'CLOSE_TICKETSQUOTES_REQUEST', customer_id});
                            this.props.dispatch({type: 'OPEN_QUOTES_REQUEST', customer_id});
                            this.props.dispatch({type: 'ACCEPTED_QUOTES_REQUEST', customer_id});
                            this.props.dispatch({type: 'CLOSED_QUOTES_REQUEST', customer_id});
                            this.props.dispatch({type: 'USERS_REQUEST', customer_id});
                            this.props.dispatch({type: 'AUTHOFFICER_REQUEST', customer_id});
                        }
                    }}
                    filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {customersOptions}
                </Select>
                <span className="userWelcomeName">{this.state.name}</span>
                <a onClick={() => {
                    sessionStorage.clear();
                    window.location.reload();
                    window.location.href = `${GlobalParameters.website}/auth`;
                }}><span className="glyphicon glyphicon-off"></span>&nbsp;&nbsp;Logout</a>
            </div>

        </div>;
        const {history} = this.props;
        return (
            <div>
                <div className="NavHeadContainer">
                    <div className="logoContainer">
                        <a href="https://mansol.net.au" className="logo" target="_blank">managed solutions</a>
                    </div>
                    <div className="container">
                        <div className="responsiveMain">
                            {header}
                        </div>
                        <div className="responsiveSecond">
                            <a href="javascript:void(0);" className="icon" onClick={() => {
                                this.setState({visible: !this.state.visible})
                            }}>
                                <i className="fa fa-bars"></i>
                            </a>
                            {this.state.visible ? headersecond : ''}
                        </div>


                    </div>

                </div>

            </div>
        )
    }
}

export default connect()(withRouter(NavHead))