import React from "react";
import { Input,Button } from 'antd';

const { TextArea } = Input;
class Correspondence extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailContent:'this is a test my friend'
        }
    }
    render() {
        return (
            <div className="container" style={{marginTop:30}}>
                <div>To: <span style={{fontWeight:500}}>navara_seat_covers</span></div>
                <div style={{marginBottom:15}}>Subject:Re:navara_seat_covers sent a message about FRONT FB+MP & REAR+Armrest Fit Mitsubishi Triton MQ Neoprene Waterproof #253659984086</div>
                <TextArea rows={4} />
                <TextArea rows={4} disabled={true} value={this.state.emailContent} />
                <div style={{marginTop:10}}><Button type='primary'>Send Messages</Button></div>
            </div>
        )
    }

}

export default Correspondence