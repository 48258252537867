import { takeLatest } from 'redux-saga/effects';
import { loginAuth } from './LoginSaga';
import { securityQuestionsQuery } from './SecuritySaga';
import {ticketsQuotesQuery,openTicketsQuotesQuery,closeTicketsQuotesQuery,openQuotesQuery,acceptedQuotesQuery,closedQuotesQuery,usersQuery,authOfficerQuery} from "./TicketsQuotesSaga";
import { getCategoryList, getSelectedClient } from './CreateJobSaga';

// Watches for SEARCH_MEDIA_REQUEST action type asynchronously
export default function* watcher() {
    yield takeLatest("LOGGEDIN_REQUEST", loginAuth);
    yield takeLatest("TICKETSQUOTES_REQUEST", ticketsQuotesQuery);
    yield takeLatest("OPEN_TICKETSQUOTES_REQUEST", openTicketsQuotesQuery);
    yield takeLatest("CLOSE_TICKETSQUOTES_REQUEST", closeTicketsQuotesQuery);
    yield takeLatest("OPEN_QUOTES_REQUEST", openQuotesQuery);
    yield takeLatest("ACCEPTED_QUOTES_REQUEST", acceptedQuotesQuery);
    yield takeLatest("CLOSED_QUOTES_REQUEST", closedQuotesQuery);
    yield takeLatest("USERS_REQUEST", usersQuery);
    yield takeLatest("AUTHOFFICER_REQUEST", authOfficerQuery);
    yield takeLatest("SECUIRTY_REQUEST", securityQuestionsQuery);
    yield takeLatest("GET_CATEGORY_LIST_REQUEST", getCategoryList);
    yield takeLatest("GET_SELECTED_CLIENT_REQUEST", getSelectedClient);
}