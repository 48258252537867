import React from "react";
import './JobsheetConfirmation.css';
import {Input, Spin,  Button} from 'antd';
import request from "../../utils/request";
import qs from "qs";
const queryString = require('query-string');
const {TextArea} = Input;

import GlobalParameters from "../../utils/globalParameters";

class JobsheetConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ticket_id: '',
            subject: '',
            loading: true,
            comments: '',
            stars: null,
            completed_date: '',
            start_one: true,
            start_two: true,
            start_three: true,
            start_four: true,
            start_five: true,
            feedback: 2,
            message: '',
            job_status: '',
            reopen_reason: null
        }
    }

    setStars = (star) => {
        if (star === 1) {
            this.setState({
                start_one: true,
                start_two: false,
                start_three: false,
                start_four: false,
                start_five: false,
                stars: star
            })
        }
        if (star === 2) {
            this.setState({
                start_one: true,
                start_two: true,
                start_three: false,
                start_four: false,
                start_five: false,
                stars: star
            })
        }
        if (star === 3) {
            this.setState({
                start_one: true,
                start_two: true,
                start_three: true,
                start_four: false,
                start_five: false,
                stars: star
            })
        }
        if (star === 4) {
            this.setState({
                start_one: true,
                start_two: true,
                start_three: true,
                start_four: true,
                start_five: false,
                stars: star
            })
        }
        if (star === 5) {
            this.setState({
                start_one: true,
                start_two: true,
                start_three: true,
                start_four: true,
                start_five: true,
                stars: star
            })
        }
    }

    componentDidMount() {

        let query = queryString.parse(this.props.location.search)
        request(`${GlobalParameters.website}/erams/api/cportal/jobsheet?confirm=1&id=${query.id}&key=${query.key}`, {}).then(({data}) => {
            let ticket_id = data.jobsheet.ticket_id;
            let subject = data.jobsheet.subject;
            let completed_date = data.jobsheet.completed_date;
            let job_status = data.jobsheet.job_status;
            let reopen_reason = data.jobsheet.reopen_reason;
            let stars = data.jobsheet.feedback_stars;
            let comments = data.jobsheet.feedback_comments;

            this.setState({
                ticket_id,
                subject,
                completed_date,
                job_status,
                reopen_reason,
                stars,
                comments
            }, () => {
                if(query.star){
                    const values = {};
                    let query = queryString.parse(this.props.location.search)
                    values.ticket_id = query.id;
                    values.stars = query.star;
                    values.comments = this.state.comments;
                    values.key = query.key;

                    this.setState({stars: parseInt(query.star)});


                    request(`${GlobalParameters.website}/erams/api/cportal/jobsheet_confirm`,  {method: 'POST', data:values},null,0).then((data) => {
                        if (data.data.type == 'exception') {
                            this.setState({feedback: 0, message: data.data.message, loading: false})
                        } else {
                            this.setState({feedback: 0, message: 'Thank you for confirming this work', job_status: 'completed', loading: false})
                        }
                    })
                }else {
                    this.setState({loading: false})
                }
            });
        });
    }

    render() {


        return (
            <Spin spinning={this.state.loading}>
                <div className='container measurePerformance'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="header">
                                <div>Report #: <span className="ticketId">{this.state.ticket_id}</span></div>
                                <div className="logoConfirm"><span style={{color: '#010080'}}>managed</span> <span
                                    style={{color: '#fff', backgroundColor: '#010080', padding: '0 6px'}}>solutions</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="address">
                                <div><span className="fontBold">PROFESSIONAL SERVICES REPORT</span></div>
                                <div><span style={{color: 'blue'}}>266 Brisbane Street, West Ipswich QLD</span></div>
                                <div><span className="fontBold">Ph: </span><span style={{color: 'blue'}}>1300 626 765</span>
                                </div>
                                <div><span className="fontBold">Fax: </span><span style={{color: 'blue'}}>(07) 3812 1751</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="address">
                                <ul className="jobSheetul">
                                    <li className="jobSheetli">
                                        <span style={{fontSize: '16px', fontWeight: 'bold'}}>Job Reference:</span> {this.state.ticket_id}
                                    </li>
                                    <li className="jobSheetli">
                                        <span style={{fontSize: '16px', fontWeight: 'bold'}}>Job Completed:</span> {this.state.completed_date}
                                    </li>
                                    <li className="jobSheetli">
                                        <span style={{fontSize: '16px', fontWeight: 'bold'}}>Subject:</span> {this.state.subject}
                                    </li>
                                </ul>

                                <div className="clearfix">

                                </div>



                            </div>
                        </div>
                    </div>

                    {this.state.job_status === 'open' ?
                        <div className="row">
                            <div className="col-md-12">
                                <div style={{marginTop: 8}}>
                                    <div style={{marginBottom: 10, color: 'red',textAlign:'center'}}>
                                        <div>
                                            <div>
                                                Thank you for confirming this work ticket.
                                            </div>
                                            <div>
                                                Since the confirmation request was sent to you, we have re-opened the ticket{!this.state.reopen_reason?<span>.</span>:''} {this.state.reopen_reason?<span> because {this.state.reopen_reason}.</span>:''}
                                            </div>
                                            <div>
                                                We will send you another confirmation request when this is resolved.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : ''}
                    {this.state.job_status === 'completed' ?
                        <div className="row">
                            <div className="col-md-12">
                                <div style={{marginTop: 8}}>
                                    <div style={{marginBottom: 10, textAlign:'center'}}>
                                        <div>
                                            <h3>
                                                Thank you for confirming that the work we have done is completed to your satisfaction.
                                            </h3>
                                            <h3>
                                                We look forward to doing business with you again.
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-12">
                                    <div style={{marginTop: 8}}>
                                        <div style={{textAlign: 'center'}}><h3>You have rated our service for this job:</h3></div>
                                        <div className="faceStyle">

                                            <div
                                                className={!this.state.stars ? "imgUntouchedSelectStyle" : this.state.stars === 1 ? "imgSelectStyle" :  "imgStyle"}
                                                onClick={() => {
                                                    this.setState({stars: 1})
                                                }}><img src={`${GlobalParameters.website}/images/common/smiley1.png`}
                                                        style={{width: 75, height: 100}}/></div>
                                            <div
                                                className={!this.state.stars ? "imgUntouchedSelectStyle" : this.state.stars === 2 ? "imgSelectStyle" :  "imgStyle"}
                                                onClick={() => {
                                                    this.setState({stars: 2})
                                                }}><img src={`${GlobalParameters.website}/images/common/smiley2.png`}
                                                        style={{width: 75, height: 100}}/></div>

                                            <div
                                                className={!this.state.stars ?"imgUntouchedSelectStyle" : this.state.stars === 3 ? "imgSelectStyle" : "imgStyle"}
                                                onClick={() => {
                                                    this.setState({stars: 3})
                                                }}><img src={`${GlobalParameters.website}/images/common/smiley3.png`}
                                                        style={{width: 75, height: 100}}/></div>

                                            <div
                                                className={!this.state.stars ? "imgUntouchedSelectStyle" : this.state.stars === 4 ? "imgSelectStyle" : "imgStyle"}
                                                onClick={() => {
                                                    this.setState({stars: 4})
                                                }}><img src={`${GlobalParameters.website}/images/common/smiley4.png`}
                                                        style={{width: 75, height: 100}}/></div>

                                            <div
                                                className={!this.state.stars ? "imgUntouchedSelectStyle" : this.state.stars === 5 ? "imgSelectStyle" : "imgStyle"}
                                                onClick={() => {
                                                    this.setState({stars: 5})
                                                }}><img src={`${GlobalParameters.website}/images/common/smiley5.png`}
                                                        style={{width: 75, height: 100}}/></div>

                                            {/*<div*/}
                                            {/*  className={!this.state.stars ? "imgUntouchedSelectStyle" : this.state.stars === 1 ? "imgSelectStyle" :  "imgStyle"}*/}
                                            {/*  onClick={() => {*/}
                                            {/*    this.setState({stars: 1})*/}
                                            {/*  }}><img src={`${GlobalParameters.webserver}/images/004-emoji-1.png`}*/}
                                            {/*          style={{width: 50, height: 50}}/></div>*/}

                                            {/*<div*/}
                                            {/*  className={!this.state.stars ?"imgUntouchedSelectStyle" : this.state.stars === 2 ? "imgSelectStyle" : "imgStyle"}*/}
                                            {/*  onClick={() => {*/}
                                            {/*    this.setState({stars: 2})*/}
                                            {/*  }}><img src={`${GlobalParameters.webserver}/images/002-emoji.png`}*/}
                                            {/*          style={{width: 50, height: 50}}/></div>*/}

                                            {/*<div*/}
                                            {/*  className={!this.state.stars ? "imgUntouchedSelectStyle" : this.state.stars === 3 ? "imgSelectStyle" : "imgStyle"}*/}
                                            {/*  onClick={() => {*/}
                                            {/*    this.setState({stars: 3})*/}
                                            {/*  }}><img src={`${GlobalParameters.webserver}/images/001-smile.png`}*/}
                                            {/*          style={{width: 50, height: 50}}/></div>*/}


                                        </div>
                                    </div>

                                    <div style={{marginBottom: 10}}>
                                        {this.state.stars ? this.state.stars <= 3 ? <h5>What could we have done better?</h5> :
                                            <h5>You can leave a comment here to go to the person who did your work:</h5> : ''}
                                        {this.state.stars ? <TextArea autosize={{minRows: 3, maxRows: 3}}
                                                                      value={this.state.comments} onChange={(e) => {
                                            this.setState({comments: e.target.value});
                                        }}/> : ''}</div>

                                </div>
                            </div>


                        </div> : ''}
                    {/*{this.state.feedback !== 1 &&  this.state.job_status === 'confirmed'? <div className="row">*/}
                    {this.state.feedback !== 1 &&  this.state.job_status === 'confirmed'? <div className="row">
                        <div className="col-md-12">
                            <div style={{marginBottom: 10, textAlign:'center'}}>
                                <div>
                                    <h3>
                                        Thank you for confirming that the work we have done is completed to your satisfaction.
                                    </h3>
                                    <h3>
                                        We look forward to doing business with you again.
                                    </h3>
                                </div>
                            </div>
                            <div style={{marginTop: 8}}>
                                <div style={{textAlign: 'center'}}><h3>Please rate our service for this job</h3></div>
                                <div className="faceStyle">

                                    <div
                                        className={!this.state.stars ? "imgUntouchedSelectStyle" : this.state.stars === 1 ? "imgSelectStyle" :  "imgStyle"}
                                        onClick={() => {
                                            this.setState({stars: 1})
                                        }}><img src={`${GlobalParameters.website}/images/common/smiley1.png`}
                                                style={{width: 75, height: 100}}/></div>

                                    <div
                                        className={!this.state.stars ? "imgUntouchedSelectStyle" : this.state.stars === 2 ? "imgSelectStyle" :  "imgStyle"}
                                        onClick={() => {
                                            this.setState({stars: 2})
                                        }}><img src={`${GlobalParameters.website}/images/common/smiley2.png`}
                                                style={{width: 75, height: 100}}/></div>

                                    <div
                                        className={!this.state.stars ?"imgUntouchedSelectStyle" : this.state.stars === 3 ? "imgSelectStyle" : "imgStyle"}
                                        onClick={() => {
                                            this.setState({stars: 3})
                                        }}><img src={`${GlobalParameters.website}/images/common/smiley3.png`}
                                                style={{width: 75, height: 100}}/></div>

                                    <div
                                        className={!this.state.stars ? "imgUntouchedSelectStyle" : this.state.stars === 4 ? "imgSelectStyle" : "imgStyle"}
                                        onClick={() => {
                                            this.setState({stars: 4})
                                        }}><img src={`${GlobalParameters.website}/images/common/smiley4.png`}
                                                style={{width: 75, height: 100}}/></div>

                                    <div
                                        className={!this.state.stars ? "imgUntouchedSelectStyle" : this.state.stars === 5 ? "imgSelectStyle" : "imgStyle"}
                                        onClick={() => {
                                            this.setState({stars: 5})
                                        }}><img src={`${GlobalParameters.website}/images/common/smiley5.png`}
                                                style={{width: 75, height: 100}}/></div>

                                    {/*<div*/}
                                    {/*  className={!this.state.stars ? "imgUntouchedSelectStyle" : this.state.stars === 1 ? "imgSelectStyle" :  "imgStyle"}*/}
                                    {/*  onClick={() => {*/}
                                    {/*    this.setState({stars: 1})*/}
                                    {/*  }}><img src={`${GlobalParameters.webserver}/images/004-emoji-1.png`}*/}
                                    {/*          style={{width: 50, height: 50}}/></div>*/}

                                    {/*<div*/}
                                    {/*  className={!this.state.stars ?"imgUntouchedSelectStyle" : this.state.stars === 2 ? "imgSelectStyle" : "imgStyle"}*/}
                                    {/*  onClick={() => {*/}
                                    {/*    this.setState({stars: 2})*/}
                                    {/*  }}><img src={`${GlobalParameters.webserver}/images/002-emoji.png`}*/}
                                    {/*          style={{width: 50, height: 50}}/></div>*/}

                                    {/*<div*/}
                                    {/*  className={!this.state.stars ? "imgUntouchedSelectStyle" : this.state.stars === 3 ? "imgSelectStyle" : "imgStyle"}*/}
                                    {/*  onClick={() => {*/}
                                    {/*    this.setState({stars: 3})*/}
                                    {/*  }}><img src={`${GlobalParameters.webserver}/images/001-smile.png`}*/}
                                    {/*          style={{width: 50, height: 50}}/></div>*/}


                                </div>
                            </div>

                            <div style={{marginBottom: 10}}>
                                {this.state.stars ? this.state.stars <= 3 ? <h5>What could we have done better?</h5> :
                                    <h5>You can leave a comment here to go to the person who did your work:</h5> : ''}
                                {this.state.stars ? <TextArea autosize={{minRows: 3, maxRows: 3}}
                                                              value={this.state.comments} onChange={(e) => {
                                    this.setState({comments: e.target.value});
                                }}/> : ''}</div>

                            {this.state.feedback === 0 ? <div style={{marginBottom: 10, color: 'red'}}>
                                {this.state.message}
                            </div> : ''}

                        </div>
                    </div> : ''}


                    {this.state.feedback !== 1 && this.state.job_status !== 'open' ? <div style={{textAlign: 'center'}}>
                        {this.state.stars ?
                            <Button type="primary" onClick={() => {
                                const values = {};
                                let query = queryString.parse(this.props.location.search)
                                values.ticket_id = query.id;
                                values.stars = this.state.stars;
                                values.comments = this.state.comments;
                                values.key = query.key;


                                request(`${GlobalParameters.website}/erams/api/cportal/jobsheet_confirm`, {
                                    method: 'POST',
                                    data: values
                                }, null, 0).then((data) => {
                                    if (data.data.type == 'exception') {
                                        // message.error(data.data.message);
                                        this.setState({feedback: 0, message: data.data.message})
                                    } else {
                                        // message.success(data.data.message);
                                        this.setState({
                                            feedback: 1,
                                            message: 'Thank you for confirming this work',
                                            job_status: 'completed'
                                        })
                                    }
                                })
                            }}>
                                Submit
                            </Button> : ''}
                    </div> : ''}

                </div>
            </Spin>
        )
    }

}


export default JobsheetConfirmation;