import React from "react";
import {Select,Input,Button,message} from 'antd';
import request from '../../utils/request';
import GlobalParameters from '../../utils/globalParameters';
import {connect} from "react-redux";
import '../../scss/mixin.css'
const Option = Select.Option;

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: null,
            newPassword: null,
            repeatedNewPassword: null,
        };
    };

    componentDidMount() {

    }

    render() {

        return (
            <div>
                <div className="marginTop">
                </div>
                < div
                    className="container">

                    < div
                        className="panel panel-success">
                        < div
                            className="panel-heading">
                            < h3 className="panel-title"> Reset Password </h3>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <span style={{marginRight:3}}>Old password</span>
                                </div>
                            </div>
                            <div className="marginTop5"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Input placeholder="" style={{width: '99%'}} type="password" onChange={(e)=> {
                                        this.setState({oldPassword: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="marginTop10"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <span style={{marginRight:3}}>New password </span>
                                </div>
                            </div>
                            <div className="marginTop5"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Input placeholder="" style={{width: '99%'}} type="password" onChange={(e)=> {
                                        this.setState({newPassword: e.target.value})
                                    }}/>
                                </div>
                            </div>
                            <div className="marginTop10"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <span style={{marginRight:3}}>New password confirmation </span>
                                </div>
                            </div>

                            <div className="marginTop5"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Input placeholder="" style={{width: '99%'}} type="password" onChange={(e)=> {
                                        this.setState({repeatedNewPassword: e.target.value})
                                    }}/>
                                </div>
                            </div>

                            <div className="marginTop10"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Button type="primary" onClick={()=>{
                                        if (this.state.newPassword !== this.state.repeatedNewPassword) {
                                            message.error("Passwords do not match");
                                            return;
                                        }
                                        if (this.state.newPassword.length < 8) {
                                            message.error(" The password length must be at least 8 characters.");
                                            return;
                                        }
                                        if (this.state.oldPassword.length < 8) {
                                            message.error( " The password length must be at least 8 characters.");
                                            return;
                                        }
                                        var patrn = /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)(?![a-zA-z\d]+$)(?![a-zA-z!@#$%^&*]+$)(?![\d!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/;
                                        if (!patrn.exec(this.state.oldPassword)) {
                                            message.error("The password must contain an uppercase letter, a number and a special character (! @ # $ % ^ & * ?)");
                                            return;
                                        }
                                        if (!patrn.exec(this.state.newPassword)) {
                                            message.error("The password must contain an uppercase letter, a number and a special character (! @ # $ % ^ & * ?)");
                                            return;
                                        }
                                        if (this.state.oldPassword.search(/[A-Z]/) < 0) {
                                            message.error("The password must contain an uppercase letter, a number and a special character (! @ # $ % ^ & * ?)");
                                            return;
                                        }
                                        if (this.state.newPassword.search(/[A-Z]/) < 0) {
                                            message.error("The password must contain an uppercase letter, a number and a special character (! @ # $ % ^ & * ?)");
                                            return;
                                        }
                                        const values = {};
                                        values.current_password =this.state.oldPassword;
                                        values.password =this.state.newPassword;

                                        request(`${GlobalParameters.website}/erams/api/cportal/contacts/password_reset`, {
                                            method: 'POST',
                                            data: values
                                        }).then((response) => {
                                            if(response.data[0]==='exception'){
                                                message.error('It failed.');
                                            }else{
                                                message.success('Password reset successfully')
                                            }
                                        });
                                    }}>Submit</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default ResetPassword;
