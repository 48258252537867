import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import AppHomePage from "./AppHomePage";
import AuthorisedOfficer from "./AuthorisedOfficer";
import SecurityQuestions from "./SecurityQuestions";
import ResetPassword from "./ResetPassword";
import NavHead from './NavHead'
import ClientFormBuilderDemo from "./ClientFormBuilderDemo";
import Correspondence from "./Correspondence";
import CreateJob from './CreateJob';

const PrimaryLayout = ({ match }) => (

    <div className="primary-layout">
        <header>
            <NavHead/>
        </header>
        <main>
            <Switch>
                <Route path={`${match.path}`} exact component={AppHomePage} />
                <Route path={`${match.path}/authOfficer`} exact component={AuthorisedOfficer} />
                <Route path={`${match.path}/secureReset`} exact component={SecurityQuestions} />
                <Route path={`${match.path}/resetPassword`} exact component={ResetPassword} />
                {/*<Route path={`${match.path}/formBuilder`} exact component={FormBuilderDemo} />*/}
                <Route path={`${match.path}/forms`} exact component={ClientFormBuilderDemo} />
                <Route path={`${match.path}/correspondence`} exact component={Correspondence} />
                <Route path={`${match.path}/createJob`} exact component={CreateJob} />
                <Redirect to={`${match.url}`} />
            </Switch>
        </main>
    </div>
)

export default PrimaryLayout