import request from '../utils/request';
import GlobalParameters from '../utils/globalParameters';

export async function ticketsQuotesQuery(customer_id) {
    return request(`${GlobalParameters.website}/erams/api/cportal/jobs?customer_id=${customer_id}` + "&status=confirmation sent", {method: 'get'});
}
export async function openTicketsQuotesQuery(customer_id) {
    return request(
        `${
            GlobalParameters.website
        }/erams/api/cportal/jobs?customer_id=${customer_id}` +
            "&status=new&status=open&status=stalled&status=unhandled&status=pending",
        { method: "get" }
    );
}
export async function closeTicketsQuotesQuery(customer_id) {
    return request(`${GlobalParameters.website}/erams/api/cportal/jobs?customer_id=${customer_id}` + "&status=resolved", {method: 'get'});
}
export async function openQuotesQuery(customer_id) {
    return request(`${GlobalParameters.website}/erams/api/cportal/quotes?customer_id=${customer_id}` + "&status=issued", {method: 'get'});
}
export async function acceptedQuotesQuery(customer_id) {
    return request(`${GlobalParameters.website}/erams/api/cportal/quotes?customer_id=${customer_id}` + "&status=accepted by client&status=accepted by ManSol&status=invoiced&status=approved by finance", {method: 'get'});
}
export async function closedQuotesQuery(customer_id) {
    return request(`${GlobalParameters.website}/erams/api/cportal/quotes?customer_id=${customer_id}` + "&status=lost&status=expired&status=canceled&status=rejected&status=replaced", {method: 'get'});
}
export async function usersQuery(customer_id) {
    return request(`${GlobalParameters.website}/erams/api/cportal/user_list?customer_id=${customer_id}`, {method: 'get'});
}
export async function authOfficerQuery(customer_id) {
    return request(`${GlobalParameters.website}/erams/api/cportal/contacts?customer_id=${customer_id}`, {method: 'get'});
}
