import { createStore, combineReducers ,applyMiddleware,compose} from 'redux'
import rootReducer from '../reducers/rootReducer'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../sagas/index'

const reduxDevTools =
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
// mount it on the Store
const store = createStore(
    rootReducer,
    applyMiddleware(sagaMiddleware)
)
// const store = createStore(
//     rootReducer,
//     compose(applyMiddleware(sagaMiddleware), reduxDevTools)
// )

// then run the saga
sagaMiddleware.run(rootSaga)
export default store