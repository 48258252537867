import React from 'react';
import {
  Button,
  Input,
  Form,
  Tooltip,
  Radio,
  message,
  Icon,
  Modal,
} from 'antd';
import { withRouter } from 'react-router-dom';
import request from '../../utils/request';
import GlobalParameters from '../../utils/globalParameters';
import { connect } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import '../../scss/mixin.css';

const { success } = Modal;
class CreateJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: null,
      isLoading: false,
    };
  }
  componentDidMount() {
    document.title = 'Lodge a Support Ticket / Job / Request for Help or Quote';
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (!nextProps.clientDetails.is_authorized) {
      window.location.replace(window.location.origin + '/client');
    }
    return true;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        if (this.state.description) {
          values.description = this.state.description;
        }

        request(
          `${GlobalParameters.website}/erams/api/cportal/client/job/${
            this.props.clientDetails.id
          }`,
          {
            method: 'POST',
            data: values,
          }
        )
          .then((response) => {
            if (response.data.type === 'exception') {
              this.setState({ isLoading: false });
              return message.error(response.data.message);
            }
            const isQuote = response.data.hasOwnProperty('quote_id');
            success({
              title: `${response.data.message}`,
              closable: false,
              keyboard: false,
              width: 520,
              content: (
                <span>
                  <p>
                    Thank you for creating the {isQuote ? ' quote ' : ' ticket '} with number {isQuote ? 'Q' : 'E'}
                    {isQuote ? response.data.quote_id : response.data.ticket_id}.
                  </p>
                  <p>We will process this as soon as we can.</p>
                  <p>
                    If this needs more immediate attention – please contact us
                    on{' '}
                    <span style={{ whiteSpace: 'nowrap' }}>1300 626 765</span>{' '}
                    to ensure that we recognise the urgency and handle the
                    {isQuote ? ' quote ' : ' ticket '} appropriately.
                  </p>
                  <p>
                    Otherwise we will process this after any currently scheduled
                    work.
                  </p>
                </span>
              ),
              onOk() {
                window.location.replace(window.location.origin + '/client');
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div className="marginTop">
          <div className="container">
            <div className="panel panel-success">
              <div className="panel-heading">
                <h3 className="panel-title">
                  {' '}
                  Lodge a Support Ticket / Job / Request for Help or Quote{' '}
                </h3>
              </div>
              <div className="panel-body">
                <Form
                  layout="horizontal"
                  labelCol={{ span: 3 }}
                  wrapperCol={{ span: 21 }}
                  onSubmit={this.handleSubmit}
                >
                  <Form.Item label="Is this" style={{ marginBottom: 0 }}>
                    {getFieldDecorator('classification', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select a classification.',
                        },
                      ],
                    })(
                      <Radio.Group>
                        <Radio
                          value="i"
                          style={{ display: 'block', whiteSpace: 'inherit' }}
                        >
                          An Incident - report to us that something is not
                          working properly.&nbsp;
                          <Tooltip
                            placement="right"
                            title="or needs to be fixed (for example: an unplanned interruption to a service or equipment or reduction in the quality of a service)"
                          >
                            <Icon type="info-circle" theme="twoTone" />
                          </Tooltip>
                        </Radio>
                        <Radio
                          value="r"
                          style={{ display: 'block', whiteSpace: 'inherit' }}
                        >
                          A Request - something you are asking us to do.
                        </Radio>
                        <Radio
                          value="q"
                          style={{ display: 'block', whiteSpace: 'inherit' }}
                        >
                          A Quote Request - if you need a quote or an estimate for a product, service or project.
                        </Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>

                  <Form.Item label="Brief Summary" style={{ marginBottom: 0 }}>
                    {getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input brief summary.',
                        },
                      ],
                    })(
                      <Input
                        placeholder={
                          'e.g Workstation offline OR Please give me a quote for a new phone system'
                        }
                      />
                    )}
                  </Form.Item>

                  <Form.Item label="Description" style={{ marginBottom: 0 }}>
                    {getFieldDecorator('description', {
                      rules: [
                        {
                          required: false,
                          message: 'Please input a description.',
                        },
                      ],
                    })(
                      <Editor
                        // apiKey={'20lb42z2tjn7er6hdp7zwsnd835tlzqmily5qr5v2frn5qkj'}
                        apiKey={
                          '4uya6ys1nflwaqrnkftg12wbj8i6vrepz1im7nxork5xask0'
                        }
                        onChange={(e) => {
                          this.setState({
                            description: e.target.getContent(),
                          });
                        }}
                        onInit={(e) => {
                          if (e.target.getContent() == '') {
                            e.target.setContent(
                              `<p id="pl" style="color:#a9a9a9; opacity: 65%"}}>Tell us as much information as you can to help us respond. You can copy/paste anything that may be useful into this space (including documents, screenshots, and images).</p>`
                            );
                          }
                        }}
                        onFocus={(e) => {
                          const place = e.target.contentDocument.getElementById(
                            'pl'
                          );
                          return place ? place.remove() : null;
                        }}
                        onBlur={(e) => {
                          if (e.target.getContent() == '') {
                            e.target.setContent(
                              `<p id="pl" style="color:#a9a9a9; opacity: 65%"}}>Tell us as much information as you can to help us respond. You can copy/paste anything that may be useful into this space (including documents, screenshots, and images).</p>`
                            );
                          }
                        }}
                        init={{
                          toolbar_items_size: 'small',
                          menubar: false,
                          invalid_styles: 'float position',
                          plugins:
                            'powerpaste image link autoresize autolink textcolor table hr charmap fullscreen lists',
                          toolbar:
                            'cut copy paste pastetext | undo redo link unlink image table hr charmap fullscreen bold italic backcolor | removeformat bullist numlist outdent indent | alignleft aligncenter alignright alignjustify | styleselect',
                          autoresize_min_height: 150,
                          insert_button_items: 'image link',
                          autoresize_max_height: 400,
                          autoresize_bottom_margin: 20,
                          powerpaste_word_import: 'merge',
                          powerpaste_html_import: 'merge',
                          branding: false,
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 3, offset: 21 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: '100%' }}
                      loading={this.state.isLoading}
                    >
                      Create Ticket
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientDetails: state.createJobState.clientDetails,
  };
};

const WrappedCreateJob = Form.create({ name: 'createJob_form' })(CreateJob);

export default connect(mapStateToProps)(withRouter(WrappedCreateJob));
