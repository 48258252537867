import React from 'react';
import '../../scss/mixin.css'
import './Login.css'
import {Link} from 'react-router-dom'
import {Form, Icon, Input, Button, Checkbox} from 'antd';
import request from '../../utils/request';
import GlobalParameters from '../../utils/globalParameters';
import {message} from "antd/lib/index";

const FormItem = Form.Item;

class SendEmailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            message:null,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleSubmit(e) {
        e.preventDefault();
        let self=this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                request(`${GlobalParameters.website}/erams/api/security/reset`, {
                    method: 'PUT',
                    data: values
                }).then((response) => {
                    if (response.data[0] == "exception") {
                        message.error("Sorry," + response.data[1]);
                        self.setState({message: response.data[1]});
                        return;
                    }else{
                        message.success(response.data[1]);
                        self.setState({message: response.data[1]});
                    }
                });
            }
        });
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        let logoSource = require(`../../assets/logo-mansol.gif`);
        let lineSource = require(`../../assets/footer-line.jpg`);
        return (
            <div className="loginStyle">
                <div className="centerBox">
                    <img src={logoSource} className="img-responsive logo"></img>
                    <img src={lineSource} className="img-responsive lineImage"></img>

                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <FormItem>
                            {getFieldDecorator('email', {
                                rules: [{
                                    type: 'email', message: 'The input is not valid email!',
                                }, {required: true, message: 'Please input your email!'}],
                            })(
                                <Input prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                       placeholder="Email"/>
                            )}
                        </FormItem>

                        <FormItem>
                            <div>
                                {this.state.message?<h4>{this.state.message}</h4>:''}
                                <div className="clearfix"></div>
                            </div>
                            <div className="loginFooter">

                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    Request Password Recovery Email
                                </Button>

                            </div>
                            <div>
                                <a className="email-forgot" onClick={()=>{
                                    this.props.history.push('/auth/login');
                                }}>Return to Client Portal Login Page</a>
                            </div>
                            <div className="home-link">
                                If you are having any difficulty accessing the client portal, please <a href="https://mansol.net.au/contact/">contact us</a>
                            </div>
                        </FormItem>
                    </Form>
                </div>
            </div>
        );
    }
}


const SendEmail = Form.create()(SendEmailForm);
export default SendEmail;
