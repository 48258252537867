import axios from 'axios'
import {message} from 'antd';

function parseJSON(response) {
    return response.json();
}

function checkStatus(response) {

    if (response.status >= 200 && response.status < 300) {
        return response;
    } else {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
    }


}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [config] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, config = {}, token,type = 1) {


    // token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2NsYWltcyI6e30sImp0aSI6IjI0YmJhYWM5LWIwYmItNDdjMy04ZDhjLTNhMDc3NzA1MmU3NyIsImV4cCI6MTUzMTg3Mjc3MywiZnJlc2giOmZhbHNlLCJpYXQiOjE1MzE4NjU1NzMsInR5cGUiOiJhY2Nlc3MiLCJuYmYiOjE1MzE4NjU1NzMsImlkZW50aXR5Ijo1NDAyfQ.jrmxx4oopd5bcKtwyiLP-CW3cLTGscq8mpr8YlOGJb8';
        config.headers = {
            'Accept': 'application/json, application/xml, text/play, text/html, *.*',
            'Content-Type': 'application/json; charset=utf-8',
        };

    if (type == 1) {
        token = sessionStorage.getItem("token");
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
    }
    if (type == 3) {
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
    }
    return axios(url, config)
        .then(checkStatus)
        .then(({data}) => {
            return {data};
        })
        .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('error.response');
                console.log(error.response);
                message.error(error.response.data.msg)
                // console.log(error.response.status);
                // console.log(error.response.headers);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        })
}