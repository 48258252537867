const initialState = {
    confirmTicketDataSource: [],
    backupConfirmTicketDataSource: [],
    backupOpenTicketDataSource: [],
    openTicketDataSource: [],
    closeTicketDataSource: [],
    backupCloseTicketDataSource: [],
    openQuotesDataSource: [],
    backupOpenQuotesDataSource: [],
    acceptedQuotesDataSource: [],
    backupAcceptedQuotesDataSource: [],
    closedQuotesDataSource: [],
    backupClosedQuotesDataSource: [],
    usersDataSource: [],
    backupUsersDataSource: [],
    authOfficerDataSource: [],
    manSolAssignedTeamDataSource: [],
    headerMansolAssignedTeam: [],
    headerMansolAssignedTeam: [],
    backupAuthOfficerDataSource: [],
    confirmLoading: true,
    openingLoading: true,
    closedLoading: true,
    openQuotesLoading: true,
    acceptedQuotesLoading: true,
    closedQuotesLoading: true,
    userLoading: true,

}

const ticketsQuotesReducer = (state = initialState, action) => {
    if (action.type === 'CONFIRMED_QUERY') {
        let info = action.data.data;
        let confirmTicketDataSource = [];
        for (let i = 0; i < info.length; i++) {
            let item = {};
            item.jobId = "E" + info[i].id;
            item.id = info[i].id;
            item.statusD = info[i].attributes.status;
            item.subDes = info[i].attributes.name;
            let leftLongDesc = "";
            if (item.subDes != null && item.subDes.length >= 40) {
                let groupSubDesc = item.subDes.split(" ");
                let allSubDes = "";
                for (var k = 0; k < groupSubDesc.length; k++) {
                    allSubDes = allSubDes + groupSubDesc[k] + " ";
                    if (allSubDes.length >= 40) {
                        break;
                    }
                }
                item.subDes = allSubDes;
                for (let l = k; l < groupSubDesc.length; l++) {
                    leftLongDesc = leftLongDesc + groupSubDesc[l] + " ";
                }
            }

            if (leftLongDesc) {
                item.description = leftLongDesc + info[i].attributes.description;
            } else {
                item.description = info[i].attributes.description;
            }

            if (item.description) {
                item.description = item.description.replace(/(<\/?a[^>]*>)(?!.*\1)/ig, '');
            }

            item.datet = info[i].attributes.created.substring(0, 10);

            item.ownerAge = info[i].attributes.age;
            confirmTicketDataSource.push(item);
        }

        return {
            ...state,
            confirmTicketDataSource: confirmTicketDataSource,
            backupConfirmTicketDataSource: confirmTicketDataSource,
            confirmLoading: false
        }
    }
    else if (action.type === 'IS_LOADING'){
        return{
            ...state,
            confirmLoading: true,
            openingLoading: true,
            closedLoading: true,
            openQuotesLoading: true,
            acceptedQuotesLoading: true,
            closedQuotesLoading: true,
            userLoading: true,
        }
    }
    else if (action.type === 'OPEN_QUERY') {
        let info = action.data.data;
        let openTicketDataSource = [];
        for (let i = 0; i < info.length; i++) {
            let item = {};
            item.jobId = "E" + info[i].id;
            item.id = info[i].id;
            item.statusD = info[i].attributes.status;
            item.subDes = info[i].attributes.name;
            item.classification = info[i].attributes.classification === 'i' ? 'Incident' : 'Request';
            let leftLongDesc = "";
            if (item.subDes != null && item.subDes.length >= 40) {
                let groupSubDesc = item.subDes.split(" ");
                let allSubDes = "";
                for (var k = 0; k < groupSubDesc.length; k++) {
                    allSubDes = allSubDes + groupSubDesc[k] + " ";
                    if (allSubDes.length >= 40) {
                        break;
                    }
                }
                item.subDes = allSubDes;
                for (let l = k; l < groupSubDesc.length; l++) {
                    leftLongDesc = leftLongDesc + groupSubDesc[l] + " ";
                }
            }

            if (leftLongDesc) {
                item.description = leftLongDesc + info[i].attributes.description;
            } else {
                item.description = info[i].attributes.description;
            }

            if (item.description) {
                item.description = item.description.replace(/(<\/?a[^>]*>)(?!.*\1)/ig, '');
            }

            item.datet = info[i].attributes.created.substring(0, 10);

            item.ownerAge = info[i].attributes.age;
            item.created = info[i].attributes.created;
            item.last_action = info[i].last_action;
            item.viewDescription = info[i].attributes.description;
            item.tasks = info[i].tasks;
            openTicketDataSource.push(item);
        }

        return {
            ...state,
            openTicketDataSource,
            openTicketDataSource,
            backupOpenTicketDataSource: openTicketDataSource,
            openingLoading: false
        }
    }
    else if (action.type === 'CLOSE_QUERY') {
        let info = action.data.data;
        let closeTicketDataSource = [];
        for (let i = 0; i < info.length; i++) {
            let item = {};
            item.jobId = "E" + info[i].id;
            item.id = info[i].id;
            item.statusD = info[i].attributes.status;
            item.subDes = info[i].attributes.name;
            let leftLongDesc = "";
            if (item.subDes != null && item.subDes.length >= 40) {
                let groupSubDesc = item.subDes.split(" ");
                let allSubDes = "";
                for (var k = 0; k < groupSubDesc.length; k++) {
                    allSubDes = allSubDes + groupSubDesc[k] + " ";
                    if (allSubDes.length >= 40) {
                        break;
                    }
                }
                item.subDes = allSubDes;
                for (let l = k; l < groupSubDesc.length; l++) {
                    leftLongDesc = leftLongDesc + groupSubDesc[l] + " ";
                }
            }

            if (leftLongDesc) {
                item.description = leftLongDesc + info[i].attributes.description;
            } else {
                item.description = info[i].attributes.description;
            }

            if (item.description) {
                item.description = item.description.replace(/(<\/?a[^>]*>)(?!.*\1)/ig, '');
            }

            item.datet = info[i].attributes.created.substring(0, 10);

            item.ownerAge = info[i].attributes.age;
            closeTicketDataSource.push(item);
        }

        return {
            ...state,
            closeTicketDataSource,
            backupCloseTicketDataSource: closeTicketDataSource,
            closedLoading: false
        }
    } else if (action.type === 'OPEN_QUOTE_QUERY') {
        let info = action.data.data;
        let openQuotesDataSource = [];

        for (let i = 0; i < info.length; i++) {
            let item = {};
            item.jobId = "Q" + info[i].id;
            item.id = info[i].id;
            item.title = info[i].attributes.title;
            item.staffquote = info[i].attributes.staff_quote_contact;
            item.status = info[i].attributes.status;
            item.expiry_date = info[i].attributes.expiry_date;
            if (info[i].attributes.include_detail) {
                item.description = info[i].attributes.detailed_description;
            }
            openQuotesDataSource.push(item);
        }
        return {
            ...state,
            openQuotesDataSource,
            backupOpenQuotesDataSource: openQuotesDataSource,
            openQuotesLoading: false
        }
    } else if (action.type === 'ACCEPTED_QUOTE_QUERY') {
        let info = action.data.data;
        console.info(info)
        let acceptedQuotesDataSource = [];

        for (let i = 0; i < info.length; i++) {
            let item = {};
            item.jobId = "Q" + info[i].id;
            item.id = info[i].id;
            item.title = info[i].attributes.title;
            item.staffquote = info[i].attributes.staff_quote_contact;
            item.status = info[i].attributes.status;
            if (info[i].attributes.include_detail) {
                item.description = info[i].attributes.detailed_description;
            }
            acceptedQuotesDataSource.push(item);
        }
        return {
            ...state,
            acceptedQuotesDataSource,
            backupAcceptedQuotesDataSource: acceptedQuotesDataSource,
            acceptedQuotesLoading: false
        }
    } else if (action.type === 'CLOSED_QUOTE_QUERY') {
        let info = action.data.data;
        let closedQuotesDataSource = [];

        for (let i = 0; i < info.length; i++) {
            let item = {};
            item.jobId = "Q" + info[i].id;
            item.id = info[i].id;
            item.title = info[i].attributes.title;
            item.staffquote = info[i].attributes.staff_quote_contact;
            item.status = info[i].attributes.status;
            if (info[i].attributes.include_detail) {
                item.description = info[i].attributes.detailed_description;
            }
            closedQuotesDataSource.push(item);
        }
        return {
            ...state,
            closedQuotesDataSource,
            backupClosedQuotesDataSource: closedQuotesDataSource,
            closedQuotesLoading: false
        }
    } else if (action.type === 'USER_QUERY') {
        let info = action.data;
        let usersDataSource = [];

        for (var i = 0; i < info.licences.length; i++) {
            let item = {};

            item.computer_name = info.licences[i].computer_name;
            item.end_date = info.licences[i].end_date;
            item.licence = info.licences[i].licence;
            item.start_date = info.licences[i].start_date;
            item.username = info.licences[i].username;
            usersDataSource.push(item);

        }
        return {...state, usersDataSource, backupUsersDataSource: usersDataSource, userLoading: false}
    } else if (action.type === 'AUTHOFFICER_QUERY') {
        let info = action.data.authorised_contacts.data;
        let assignedStaff = action.data.assigned_staff
        let authOfficerDataSource = [];

        let colHeader = [];
        let colData = [];

        if(Object.keys(assignedStaff).length !== 0) {
            Object.keys(assignedStaff).map((data, i) => {
                colHeader.push(data)
                const staff =  assignedStaff[data].map(ad => ad.full_name).join(", ")
                colData.push({ [data]: staff })
            })
            colData = [Object.assign(...colData)];
        } else {
            colData = [];
            colHeader = [];
        }

        for (let j = 0; j < info.length; j++) {
            let item = {};
            item.id = info[j].id;
            item.name = info[j].attributes.name;
            item.role = info[j].attributes.roles.data;
            item.cportal_enabled = info[j].attributes.cportal_enabled;
            item.site = info[j].attributes.site;
            for (var i = 0; i < item.role.length; i++) {
                item.site = item.role[i].attributes.site;
                let rolesR = item.role[i].attributes.roles
                for (let r = 0; r < rolesR.length; r++) {
                    if (rolesR[r] == 'Primary Contact') item.p1 = '0';
                    if (rolesR[r] == 'Liaison Contact') item.p2 = '0';
                    if (rolesR[r] == 'Alerts Contact') item.p3 = '0';
                    if (rolesR[r] == 'Invoice Contact') item.p4 = '0';
                    if (rolesR[r]== 'Request Quote') item.p5 = '0';
                    if (rolesR[r] == 'Purchase Services/Products') item.p6 = '0';
                    if (rolesR[r] == 'Request Tech Support') item.p7 = '0';
                    if (rolesR[r] == 'Help Desk Support') item.p8 = '0';
                    if (rolesR[r] == 'Jobsheet Contact') item.p9 = '0';
                    // if (rolesR[r] == 'Request Tech Support') // temp.p11 = '0';
                }
            }

            authOfficerDataSource.push(item);
        }
        return {
            ...state, authOfficerDataSource,
            backupAuthOfficerDataSource: authOfficerDataSource,
            headerMansolAssignedTeam: colHeader,
            manSolAssignedTeamDataSource: colData
        }
    } else if (action.type === 'TICKETS_TABLE_SORT') {
        let data = [];
        let order = 1;
        console.info(action.payload.sortKey);
        if (action.payload.sortKey === 'jobId' || action.payload.sortKey === 'datet' || action.payload.sortKey === 'ownerAge') {
            action.payload.sortKey = 'id'
        }
        if (action.payload.sortOrder === 'ascend') {
            order = 1;
        } else if (action.payload.sortOrder === 'descend') {
            order = -1;
        }
        console.info(action.payload.sortKey);
        if (action.payload.sortKey) {
            data = action.payload.uniqueDataSource.slice().sort(function (a, b) {
                // a = ('' + a[action.payload.sortKey]).toLowerCase()
                // b = ('' + b[action.payload.sortKey]).toLowerCase()
                a =  a[action.payload.sortKey]
                b = b[action.payload.sortKey]
                return (a === b ? 0 : a > b ? 1 : -1) * order
            })
        }
        if (action.payload.type === 0) {
            return {...state, confirmTicketDataSource: data}
        } else if (action.payload.type === 1) {
            return {...state, openTicketDataSource: data}
        } else if (action.payload.type === 2) {
            return {...state, closeTicketDataSource: data}
        } else if (action.payload.type === 3) {
            return {...state, openQuotesDataSource: data}
        } else if (action.payload.type === 4) {
            return {...state, acceptedQuotesDataSource: data}
        } else if (action.payload.type === 5) {
            return {...state, closedQuotesDataSource: data}
        } else if (action.payload.type === 6) {
            return {...state, usersDataSource: data}
        } else if (action.payload.type === 7) {
            return {...state, authOfficerDataSource: data}
        }


    } else if (action.type === 'TICKETS_TABLE_SEARCH') {
        let data = action.payload.uniqueDataSource;
        if (action.payload.type === 0) {
            return {...state, confirmTicketDataSource: data}
        } else if (action.payload.type === 1) {
            return {...state, openTicketDataSource: data}
        } else if (action.payload.type === 2) {
            return {...state, closeTicketDataSource: data}
        } else if (action.payload.type === 3) {
            return {...state, openQuotesDataSource: data}
        } else if (action.payload.type === 4) {
            return {...state, acceptedQuotesDataSource: data}
        } else if (action.payload.type === 5) {
            return {...state, closedQuotesDataSource: data}
        } else if (action.payload.type === 6) {
            return {...state, usersDataSource: data}
        }
    } else if (action.type === 'OFFICERS_TABLE_SORT') {

    } else if (action.type === 'TICKETS_TABLE_DELETEID') {
        let confirmTicketDataSource=state.confirmTicketDataSource.filter(el => el.jobId !== action.payload.job_id);
        console.info(confirmTicketDataSource)
        return {
            ...state,
            confirmTicketDataSource: confirmTicketDataSource,
            backupConfirmTicketDataSource: confirmTicketDataSource,
        }
    }



    return state
}

export default ticketsQuotesReducer