import React from "react";
import ReactDOM from "react-dom";
import {Provider} from 'react-redux'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import UnauthorizedLayout from './routes/auth/UnauthorizedLayout.js';
import AuthorizedRoute from './routes/auth/AuthorizedRoute.js';
import PrimaryLayout from './routes/auth/PrimaryLayout.js';
import './scss/comm.css';
import './scss/comm.css';
import store from './store/store'
import 'babel-polyfill';
import './index.css';

export const App = props => (
    <Provider store={store}>
        <BrowserRouter>
            <div>
                <Switch>
                    <AuthorizedRoute path="/client" component={PrimaryLayout} />
                    <Route path="/auth" component={UnauthorizedLayout}/>
                    <Redirect to="/auth"/>
                </Switch>
            </div>
        </BrowserRouter>
    </Provider>
)

var mountNode = document.getElementById("root") || document.createElement('div') ;
// var mountNode = document.getElementById("app");
ReactDOM.render(<App/>, mountNode);