import request from "../utils/request";
import GlobalParameters from "../utils/globalParameters";
const initialState = {
    logged: false,
    clientId:null,
    token:null,
}

const loggedUserReducer = (state = initialState, action) => {
    if (action.type === 'LOGGEDIN_REQUEST_SUCCESS') {
        let jwt_decode = require('jwt-decode');
        let decoded = jwt_decode(action.data.access_token);
        sessionStorage.setItem("logged", true);
        sessionStorage.setItem("clientId", decoded.identity);
        sessionStorage.setItem("token", action.data.access_token);
        window.location.href = `${GlobalParameters.webserver}/client`;
        // window.location.href = `http://localhost:1234/client`;
        return {...state,  logged: true, clientId:decoded.identity,token:action.data.access_token
        }
    }

    return state
}

export default loggedUserReducer