import React from 'react';
import '../../scss/mixin.css'
import './Login.css'
import { Link } from 'react-router-dom'
import {Form, Icon, Input, Button, Checkbox} from 'antd';
import { connect } from "react-redux";
import request from '../../utils/request';
import GlobalParameters from '../../utils/globalParameters';
const FormItem = Form.Item;

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            loading:false
        }
    };

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({loading:true})
                request(`${GlobalParameters.website}/erams/api/cportal/auth`, 
                    {method: 'POST', data:values},null,0)
                    .then(data => {
                        if (data) {
                            this.setState({loading:true})
                            this.props.dispatch({ type: "LOGGEDIN_REQUEST",values })
                        } else {
                            this.setState({loading:false})
                        }
                    });
            }
        });
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        let logoSource = require(`../../assets/logo-mansol.gif`) ;
        let lineSource = require(`../../assets/footer-line.jpg`) ;
        return (
            <div className="loginStyle">
                <div className="centerBox">
                    <img src={logoSource} className="img-responsive logo"></img>
                    <img src={lineSource} className="img-responsive lineImage"></img>
                    <p className="loginHeader">
                        Client Portal Login
                    </p>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <FormItem>
                            {getFieldDecorator('username', {
                                rules: [{
                                    type: 'email', message: 'Please enter a valid email address.',
                                },{required: true, message: 'Please enter your registered email address.'}],
                            })(
                                <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                       placeholder="Email"/>
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('password', {
                                rules: [{required: true, message: 'Please input your Password!'}],
                            })(
                                <Input prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} type="password"
                                       placeholder="Password"/>
                            )}
                        </FormItem>
                        <FormItem>
                            <div className="loginFooter">
                                <a className="login-form-forgot" onClick={()=>{
                                    this.props.history.push('/auth/sendEmail');
                                }}>Forgot password</a>
                                <Button type="primary" htmlType="submit" loading={this.state.loading} className="login-form-button">
                                    Sign in
                                </Button>
                            </div>
                        </FormItem>
                    </Form>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        logged: state.loggedUserState.logged
};}


const Login = Form.create()(LoginForm);
export default connect(mapStateToProps)(Login);
