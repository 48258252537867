import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

//Login page
import Login from './Login.js'
import SendEmail from './SendEmail.js'
import ForgotPassword from './ForgotPassword.js'
import SetNewPassword from './SetNewPassword.js'
import UserRegistration from './UserRegistration.js'
import Welcome from './Welcome.js'
import JobsheetConfirmation from './JobsheetConfirmation.js'


const UnauthorizedLayout = () => (
    <div className="unauthorized-layout">
        {/*

    Imagine this could be a general layout for all unauthorized pages like
    the login page, forgot password, email-verified, etc...

    For this example project, we'll just have a Login Page

    */}
        <Switch>
            <Route path="/auth/login" component={Login} />
            <Route path="/auth/sendEmail" component={SendEmail} />
            <Route path="/auth/forgotPassword" component={ForgotPassword} />
            <Route path="/auth/setNewPassword" component={SetNewPassword} />
            <Route path="/auth/register" component={UserRegistration} />
            <Route path="/auth/welcome" component={Welcome} />
            <Route path="/auth/jobsheet/confirm" component={JobsheetConfirmation} />
            <Redirect to="/auth/login" />
        </Switch>
    </div>
)

export default UnauthorizedLayout