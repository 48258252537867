import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

class AuthorizedRoute extends React.Component {


    render() {
        const { component: Component, logged, ...rest } = this.props

        console.info( sessionStorage.getItem('logged'));
        return (
            <Route {...rest} render={props => {
                    return sessionStorage.getItem('logged')
                    ? <Component {...props} />
                    : <Redirect to="/auth/login" />
            }} />
        )
    }
}

const mapStateToProps = state => {
    return {
        logged: state.loggedUserState.logged,

    };
};

export default connect(mapStateToProps)(AuthorizedRoute)