import React from "react";

import '../../scss/mixin.css'
import {withRouter } from 'react-router-dom'


class Welcome extends React.Component {

    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel panel-primary">
                            <div className="panel-heading">
                                <h3 className="panel-title">Welcome</h3>
                            </div>
                            <div className="panel-body">
                                <div className="panel panel-info">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">Welcome</h3>
                                    </div>
                                    <div className="panel-body">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-6">
                                            <div className="alert alert-success alert-dismissible" role="alert">
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span
                                                    aria-hidden="true">&times;</span></button>
                                                <p> Congratulations! Please go to the login page</p>
                                            </div>
                                            <form role="form">

                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-default" onClick={()=>{
                                                        this.props.history.push('/auth')
                                                    }}>
                                                        Go to Login
                                                    </button>
                                                </div>

                                            </form>
                                        </div>
                                        <div className="col-md-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default withRouter(Welcome);
