import { combineReducers } from 'redux';
import loggedUserReducer from './loggedUserReducer';
import ticketsQuotesReducer from './ticketsQuotesReducer';
import securityReducer from './securityReducer';
import createJobReducer from './createJobReducer';

// Combines all reducers to a single reducer function
const rootReducer = combineReducers({
    loggedUserState:loggedUserReducer,
    ticketsQuotesState:ticketsQuotesReducer,
    securityState:securityReducer,
    createJobState: createJobReducer
});

export default rootReducer;