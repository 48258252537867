import React, {Component} from "react";
import {withRouter} from 'react-router-dom';

import Form from "react-jsonschema-form";
import {Input, Select, Alert} from 'antd';
import {message} from "antd/lib/index";

import request from '../../utils/request';
import GlobalParameters from '../../utils/globalParameters';

const {TextArea} = Input;

const schema = {
    title: "Todo",
    type: "object",
    required: ["title"],
    properties: {
        title: {type: "string", title: "Title", default: "A new task"},
        done: {type: "boolean", title: "Done?", default: false}
    }
};
// {
//     "title": "Todo",
//     "type": "object",
//     "required": ["firstname","surname","email","role"],
//     "properties": {
//     "firstname": {"type": "string", "title": "First Name"},
//     "surname": {"type": "string", "title": "Surname"},
//     "email": {"type": "string", "title": "E-mail Address"},
//     "emailTwo": {"type": "string", "title": "Additional E-mail"},
//     "mobile": {"type": "string", "title": "Mobile Number"},
//     "role": {"type": "string", "title": "What is the user's role"},
//     "wheread": {"title": "Where will ad be working from?","type": "string","enum": ["0","1"],"enumNames": ["carseldine central","Taigum Square","Brackenridge Plaza","Brackenridge Tavern"]},
//     "remote": {"type": "boolean","title": "Will ad require remote server access?"},
//     "softa":{"type": "array", "title": "Software Access","items": {"type": "string","enum": ["WageEasy","TimeTarget","other"]},"uniqueItems": true},
//     "drivea":{"type": "array", "title": "Drive Access","items": {"type": "string","enum": ["WageEasy(W:)","TimeTarget(T:)","Shared Drive(P:)"]},"uniqueItems": true},
//     "additionalEmail": {"type": "string", "title": "What additional Mailboxed will the new user require access to?"},
//     "emaildg": {"type": "string", "title": "What E-mail distribution Groups should the new user be added to?"},
//     "requestorName": {"type": "string", "title": "Name of Requester"},
//     "additionalNotes": {"type": "string", "title": "Additional Notes"}
// }
// }
const log = (type) => console.log.bind(console, type);

class ClientFormBuilderDemo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // schema:
            //     `{
            //         "title": "Todo",
            //         "type": "object",
            //         "required": ["title"],
            //        "properties": {
            //             "title": {"type": "string", "title": "Title", "default": "A new task"},
            //             "done": {"type": "boolean", "title": "Done?", "default": false}
            //         }
            //     }`,
            // formSchema: {
            //     title: "Todo",
            //     type: "object",
            //     required: ["title"],
            //     properties: {
            //         title: {type: "string", title: "Title", default: "A new task"},
            //         done: {type: "boolean", title: "Done?", default: false},
            //         name: {type: "string", name: "Name", default: ""}
            //     }
            // },
            schema: null,
            formSchema: {},
            formDataSource: [],
            uiSchema: {},
            selectId: null,
        }

    };

    componentDidMount() {
        let client_id = sessionStorage.getItem('customer_id');
        request(`${GlobalParameters.website}/erams/api/cportal/client/${client_id}/form`, {method: 'get'}).then(({data}) => {
            if (data.type === 'success') {
                if (data.forms) {
                    this.setState({formDataSource: data.forms});
                }
            }
        })
    }

    //"name": {"type": "string", "name": "Name", "default": ""}
    render() {
        const columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

        },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <span>
    <a onClick={() => {
        let temp = JSON.parse(record.schema);
        let temp1 = JSON.parse(record.uischema);

        if (temp1 === null) {
            temp1 = {};
        }

        this.setState({schema: record.schema, formSchema: temp, uiSchema: temp1})
    }}> select</a>
    </span>
                ),
            }];

        let ticketParentsOptions = this.state.formDataSource.map(d => <Select.Option key={'' + d.id} title={'' + d.name}
                                                                                     value={'' + d.id} schema={d.schema}
                                                                                     uischema={d.uischema}>{d.name}</Select.Option>);
        return (
            <div className="container">
                {this.state.formDataSource.length > 0 ? <div>

                    <div style={{marginTop: 9}}>

                    </div>

                    <div>
                        Choose Form: <Select
                        size={'small'}
                        showSearch
                        allowClear={true}
                        onSelect={(value, option) => {
                            let temp = JSON.parse(option.props.schema);
                            let temp1 = JSON.parse(option.props.uischema);

                            if (temp1 === null) {
                                temp1 = {};
                            }

                            this.setState({
                                selectId: value,
                                schema: option.props.schema,
                                formSchema: temp,
                                uiSchema: temp1
                            })
                        }}
                        style={{minWidth: 300, marginBottom: 10}}
                        filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {ticketParentsOptions}
                    </Select>

                        {/*<Table columns={columns} size={'small'} pagination={false}*/}
                        {/*dataSource={this.state.formDataSource}/>*/}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {/*<div style={{margin: "5px 0"}}>Schema</div>
                        <TextArea autosize={true} value={this.state.schema} onChange={(e) => {
                            this.setState({schema: e.target.value})
                        }}/>
                        <Button type='primary' style={{marginBottom: 9, marginTop: 9}} onClick={() => {
                            let temp = JSON.parse(this.state.schema);


                            this.setState({formSchema: temp})

                        }}>Show Form</Button>
                        <Button style={{marginBottom: 9,marginLeft:9, marginTop: 9}} onClick={() => {
                            let client_id = sessionStorage.getItem('customer_id');
                            const values = {};
                            values.name = "testing";
                            values.form_schema = this.state.schema;

                            request(`${GlobalParameters.website}/erams/api/cportal/client/${client_id}/form`, {
                                method: 'post',
                                data: values
                            }).then((response) => {
                                console.info(response)
                                if (response.data.type === 'success') {
                                    message.success('Thank you for confirming that the work we have done is completed to your satisfaction. We look forward to doing business with you again.');

                                } else {
                                    message.error('It failed.')
                                }
                            });
                        }}>Add A Form</Button>*/}
                            {this.state.selectId ? <Form schema={this.state.formSchema}
                                                         uiSchema={this.state.uiSchema}
                                                         onChange={(form) => {
                                                             console.info(form)
                                                         }}
                                                         onBlur={(id, value) => {
                                                             console.info(id, value)
                                                         }}
                                                         onSubmit={({formData}) => {
                                                             console.info(formData)
                                                             let client_id = sessionStorage.getItem('customer_id');
                                                             request(`${GlobalParameters.website}/erams/api/cportal/client/job/${client_id}`, {method: 'post'}).then(({data}) => {
                                                                 if (data.type === 'success') {
                                                                     this.setState({formDataSource: data.forms});

                                                                 }
                                                             })
                                                             const values = {};
                                                             values.form_data = formData;
                                                             request(`${GlobalParameters.website}/erams/api/cportal/client/job/${client_id}`, {
                                                                 method: 'post',
                                                                 data: values
                                                             }).then((response) => {
                                                                 if (response.data.type === 'success') {
                                                                     message.success(response.data.message);

                                                                 } else {
                                                                     message.error(response.data.message)
                                                                 }
                                                             });
                                                         }}
                                                         onError={log("errors")}/> : ''}
                        </div>
                    </div>
                </div> : <div style={{marginTop: 9}}>
                    <div style={{marginTop: 20}}>
                        <Alert
                            message="There are no forms configured for your account.  Please check with your account manager if you believe this is incorrect."
                            type="error"/>
                    </div>
                </div>}

            </div>
        )
    }

}


export default withRouter(ClientFormBuilderDemo);
