import React from "react";
import {Table, Tabs,Button,Input,Pagination, Modal, Row, Col} from 'antd';
import {withRouter, Link } from 'react-router-dom'
import request from '../../utils/request';
import GlobalParameters from '../../utils/globalParameters';
import {connect} from "react-redux";
import '../../scss/mixin.css'
import {message,Popconfirm} from "antd/lib/index";
const FileSaver = require('file-saver');
const TabPane = Tabs.TabPane;
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReactDOM from 'react-dom'

class TicketQuotes extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            selectedRows:[],
            isTaskModal: false,
            tasksDataOpenTicket: [],
            openTicketDetails: {},
        }

    };

    // componentDidMount() {
    //     let myInterval = setInterval(()=>{
    //         if(sessionStorage.getItem('customer_id') ){
    //             let customer_id=sessionStorage.getItem('customer_id');
    //             this.props.dispatch({type:'TICKETSQUOTES_REQUEST',customer_id});
    //             this.props.dispatch({type:'OPEN_TICKETSQUOTES_REQUEST',customer_id});
    //             this.props.dispatch({type:'CLOSE_TICKETSQUOTES_REQUEST',customer_id});
    //             this.props.dispatch({type:'OPEN_QUOTES_REQUEST',customer_id});
    //             this.props.dispatch({type:'ACCEPTED_QUOTES_REQUEST',customer_id});
    //             this.props.dispatch({type:'CLOSED_QUOTES_REQUEST',customer_id});
    //             this.props.dispatch({type:'USERS_REQUEST',customer_id});
    //             clearInterval(myInterval);
    //         }
    //     }, 20);
    //
    //
    // }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.clientDetails && (this.props.clientDetails !== nextProps.clientDetails)){
            let customer_id = nextProps.clientDetails.id;
            this.props.dispatch({type:'IS_LOADING'});
            this.props.dispatch({type:'TICKETSQUOTES_REQUEST',customer_id});
            this.props.dispatch({type:'OPEN_TICKETSQUOTES_REQUEST',customer_id});
            this.props.dispatch({type:'CLOSE_TICKETSQUOTES_REQUEST',customer_id});
            this.props.dispatch({type:'OPEN_QUOTES_REQUEST',customer_id});
            this.props.dispatch({type:'ACCEPTED_QUOTES_REQUEST',customer_id});
            this.props.dispatch({type:'CLOSED_QUOTES_REQUEST',customer_id});
            this.props.dispatch({type:'USERS_REQUEST',customer_id});
        }
        return true;
    }

    handleCloseTasksData = () => {
        this.setState({
            isTaskModal: false,
            tasksDataOpenTicket: [],
            openTicketDetails: [],
        })
    }

    render() {
        const userColumns = [{
            title: 'Computer Name',
            dataIndex: 'computer_name',
            key: 'computer_name',
        },  {
            title: 'Licence',
            dataIndex: 'licence',
            key: 'licence',
        }, {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
        }, {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
        },{
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        }];

        const openQuoteColumns = [{
            title: 'Quote ID',
            dataIndex: 'jobId',
            width:'10%',
            key: 'jobId',
        },
        {
            title: 'Expires',
            dataIndex: 'expiry_date',
            width:'15%',
            key: 'expiry_date',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width:'10%',
            key: 'status',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Mansol Contact',
            dataIndex: 'staffquote',
            width:'10%',
            key: 'staffquote',
        }];

        const QuoteColumns = [{
            title: 'Quote ID',
            dataIndex: 'jobId',
            width:'10%',
            key: 'jobId',
        }, {
            title: 'Status',
            dataIndex: 'status',
            width:'10%',
            key: 'status',
        }, {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        }, {
            title: 'Mansol Contact',
            dataIndex: 'staffquote',
            width:'10%',
            key: 'staffquote',
        }];
        const confirmColumns = [{
            title: 'Ticket ID',
            dataIndex: 'jobId',
            sorter: true,
            key: 'jobId',
        }, {
            title: 'Status',
            dataIndex: 'statusD',
            sorter: true,
            width:'10%',
            key: 'statusD',
        }, {
            title: 'Subject / Description ',
            dataIndex: 'subDes',
            sorter: true,
            key: 'subDes',
            render: (text, record) => {
                let descriptionVisible = false;
                return <DescriptionRow subDes={record.subDes} description={record.description}/>
            },
        }, {
            title: 'Created',
            dataIndex: 'datet',
            sorter: true,
            key: 'datet',
        }, {
            title: 'Age',
            dataIndex: 'ownerAge',
            sorter: true,
            key: 'ownerAge',
        }, {
            title: 'Jobsheet',
            dataIndex: 'address',
            key: 'address',
            render: (text, record) => {
                return <a onClick={() => {
                    var ticketId = record.jobId.substring(1);
                    request(`${GlobalParameters.website}/erams/api/cportal/jobs/${ticketId}`, {
                        method: 'put',
                        responseType: "arraybuffer"
                    }, null, 1).then(function (response) {
                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });
                        FileSaver.saveAs(blob, ticketId);
                    });
                }
                } style={{'cursor': 'pointer'}}>Download</a>
            }
        },];
        const columns = [{
            title: 'Ticket ID',
            dataIndex: 'jobId',
            sorter: true,
            width:'10%',
            key: 'jobId',
        }, {
            title: 'Status',
            dataIndex: 'statusD',
            sorter: true,
            width:'10%',
            key: 'statusD',
        }, {
            title: 'Subject / Description ',
            dataIndex: 'subDes',
            sorter: true,
            key: 'subDes',
            render: (text, record) => {
                let descriptionVisible = false;
                return <DescriptionRow subDes={record.subDes} description={record.description}/>
            },
        }, {
            title: 'Created',
            dataIndex: 'datet',
            width:'10%',
            sorter: true,
            key: 'datet',
        }, {
            title: 'Age',
            dataIndex: 'ownerAge',
            width:'10%',
            sorter: true,
            key: 'ownerAge',
        }, {
            title: 'Jobsheet',
            dataIndex: 'address',
            width:'10%',
            key: 'address',
            render: (text, record) => {
                return <a onClick={() => {
                    var ticketId = record.jobId.substring(1);
                    request(`${GlobalParameters.website}/erams/api/cportal/jobs/${ticketId}`, {
                        method: 'put',
                        responseType: "arraybuffer"
                    }, null, 1).then(function (response) {
                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });
                        FileSaver.saveAs(blob, ticketId);
                    });
                }
                } style={{'cursor': 'pointer'}}>Download</a>
            }
        },];
        const openColumns = [{
            title: 'Ticket ID',
            dataIndex: 'jobId',
            sorter: true,
            width:'10%',
            key: 'jobId',
        }, {
            title: 'Status',
            dataIndex: 'statusD',
            sorter: true,
            width:'10%',
            key: 'statusD',
            render: (text, record) => {
                if(record.statusD === 'unhandled'){
                    return <span>new</span>
                }
                return <span>{text}</span>
            }
        }, {
            title: 'Subject',
            dataIndex: 'subDes',
            sorter: true,
            key: 'subDes',
            render: (text, record) => {
                let descriptionVisible = false;
                return <DescriptionRow subDes={record.subDes} description={record.description}/>
            },
        },
        {
            title: 'Created',
            dataIndex: 'datet',
            sorter: true,
            width:'10%',
            key: 'datet',
        }, {
            title: 'Age',
            dataIndex: 'ownerAge',
            sorter: true,
            width:'10%',
            key: 'ownerAge',
        }, {
            title: 'I/R',
            dataIndex: 'classification',
            sorter: true,
            width:'10%',
            key: 'classification',
        }, {
            title: 'Last Action',
            dataIndex: 'last_action',
            sorter: true,
            width:'15%',
            key: 'last_action',
        },
        {
            title: 'Action',
            dataIndex: 'tasks',
            sorter: true,
            key: 'tasks',
            render: (text, record) => {
                return (
                    <a
                        className="viewTask"
                        onClick={() => {
                            this.setState({
                                isTaskModal: true,
                                tasksDataOpenTicket: record.tasks,
                                openTicketDetails: record
                            })
                        }}
                    >
                        view
                    </a>
                )
            },
        },
    ];
        // rowSelection object indicates the need for row selection
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.setState({selectedRows:selectedRows})
            },

        };
        return (
            <div>
                <div className="marginTop">
                </div>
                < div
                    className="container">
                    {
                        this.props.clientDetails.is_authorized ?
                            <div
                                style={{margin:'10px 0', textAlign:'center'}}>
                                <Link to={'client/createJob'}>
                                    <Button type='primary'>Lodge a Support Ticket / Job / Request for Help or Quote</Button>
                                </Link>
                            </div>
                            : null
                    }
                    < div
                        className="panel panel-success">
                        < div
                            className="panel-heading">
                            < h3 className="panel-title"> Tickets and Quotes </h3>
                        </div>
                        <div className="panel-body">
                            <Tabs defaultActiveKey="1" onChange={(key)=>{
                                if(key==='7'){
                                    setTimeout(() => {
                                    const tableCon = ReactDOM.findDOMNode(this.refs['usersComputersTable'])
                                        console.info(tableCon)
                                    if (tableCon) {
                                        const table = tableCon.querySelector('table')
                                        table.setAttribute('id', 'table-to-xls')
                                    }
                                    }, 800)

                                }
                                if (key ==='3') {
                                    setTimeout(() => {
                                    const tableCon = ReactDOM.findDOMNode(this.refs['closedTicketsTable'])
                                        console.info(tableCon)
                                    if (tableCon) {
                                        const table = tableCon.querySelector('table')
                                        table.setAttribute('id', 'table-to-xls')
                                    }
                                    }, 800)

                                }
                            }}>
                                <TabPane tab="To Be Confirmed" key="1">
                                    <div className="pull-right">    <Input
                                        placeholder="Search..."
                                        style={{width: 200, float: 'right'}}
                                        size="large"
                                        onPressEnter={(e)=>{
                                            let filterKey = e.target.value.toLowerCase() || '';
                                            if (filterKey) {
                                                let filterDataSource = this.props.backupConfirmTicketDataSource.filter(function (row) {
                                                    return Object.keys(row).some(function (key) {
                                                        return String(row[key]).toLowerCase().indexOf(filterKey) > -1
                                                    })
                                                });
                                                this.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_SEARCH',
                                                        payload: {
                                                            type: 0,
                                                            uniqueDataSource:filterDataSource,
                                                        }
                                                    }
                                                )
                                            }else{
                                                this.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_SEARCH',
                                                        payload: {
                                                            type: 0,
                                                            uniqueDataSource:this.props.backupConfirmTicketDataSource,
                                                        }
                                                    }
                                                )
                                            }
                                        }}
                                    /></div>
                                    <div className="clearfix"></div>

                                    <div className="marginTop10"></div>
                                    <Table
                                        className="toBeConfirmed"
                                        rowSelection={rowSelection}
                                        columns={columns} size={'small'}
                                           rowKey={(record) => {
                                               '' + record.jobId;
                                           }}
                                           pagination={false}
                                           onChange={(pagination, filters, sorter)=>{
                                               if (sorter.field && sorter.order) {
                                                   this.props.dispatch({type:'TICKETS_TABLE_SORT',
                                                       payload: {
                                                           sortKey: sorter.field,
                                                           sortOrder: sorter.order,
                                                           type:0,
                                                           uniqueDataSource:this.props.confirmTicketDataSource
                                                       }
                                                   });

                                               }
                                           }}
                                        loading={this.props.confirmLoading}
                                           dataSource={this.props.confirmTicketDataSource}/>
                                    <div className='marginTop'></div>
                                    <Popconfirm title="Are you sure?" onConfirm={()=>{
                                        if(this.state.selectedRows.length!==1){
                                            message.error('Please select one job that you want to confirm');
                                            return;
                                        }

                                        let job_id=[];
                                        for(let i=0;i<this.state.selectedRows.length;i++){
                                            job_id.push(this.state.selectedRows[i].jobId.substring(1));
                                        }
                                        let self=this;
                                        const values = {job_id:job_id};
                                        request(`${GlobalParameters.website}/erams/api/cportal/tickets/confirm`, {
                                            method: 'post',
                                            data: values
                                        }).then((response) => {
                                            if(response.data.type==='success'){
                                                message.success('Thank you for confirming that the work we have done is completed to your satisfaction. We look forward to doing business with you again.');
                                                self.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_DELETEID',
                                                        payload: {
                                                            job_id:self.state.selectedRows[0].jobId,
                                                        }
                                                    }
                                                )
                                            }else{
                                                message.error('It failed.')
                                            }
                                        });

                                    }} onCancel={()=>{}} okText="Yes" cancelText="No">
                                    <Button type="primary" style={{width:'100%'}}>Confirm</Button></Popconfirm>

                                </TabPane>
                                <TabPane tab="Open Tickets" key="2">
                                   <div className="pull-right">    <Input
                                        placeholder="Search..."
                                        style={{width: 200, float: 'right'}}
                                        size="large"
                                        onPressEnter={(e)=>{
                                            let filterKey = e.target.value.toLowerCase() || '';
                                            if (filterKey) {
                                                let filterDataSource = this.props.backupOpenTicketDataSource.filter(function (row) {
                                                    return Object.keys(row).some(function (key) {
                                                        return String(row[key]).toLowerCase().indexOf(filterKey) > -1
                                                    })
                                                });
                                                this.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_SEARCH',
                                                        payload: {
                                                            type: 1,
                                                            uniqueDataSource:filterDataSource,
                                                        }
                                                    }
                                                )
                                            }else{
                                                this.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_SEARCH',
                                                        payload: {
                                                            type: 1,
                                                            uniqueDataSource:this.props.backupOpenTicketDataSource,
                                                        }
                                                    }
                                                )
                                            }
                                        }}
                                    /></div>
                                    <div className="clearfix"></div>

                                    <div className="marginTop10"></div>
                                    <Table columns={openColumns} size={'small'}
                                           loading={this.props.openingLoading}
                                           onChange={(pagination, filters, sorter)=>{
                                               if (sorter.field && sorter.order) {
                                                   this.props.dispatch({type:'TICKETS_TABLE_SORT',
                                                       payload: {
                                                           sortKey: sorter.field,
                                                           sortOrder: sorter.order,
                                                           type:1,
                                                           uniqueDataSource:this.props.openTicketDataSource
                                                       }
                                                   });

                                               }
                                           }}
                                           dataSource={this.props.openTicketDataSource}/>
                                </TabPane>
                                <TabPane tab="Closed Tickets" key="3">
                                    <div className="pull-right">
                                        <Input
                                            placeholder="Search..."
                                            style={{width: 200, marginRight:7}}
                                            size="large"
                                            onPressEnter={(e)=>{
                                                let filterKey = e.target.value.toLowerCase() || '';
                                                if (filterKey) {
                                                    let filterDataSource = this.props.backupCloseTicketDataSource.filter(function (row) {
                                                        return Object.keys(row).some(function (key) {
                                                            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
                                                        })
                                                    });
                                                    this.props.dispatch(
                                                        {
                                                            type: 'TICKETS_TABLE_SEARCH',
                                                            payload: {
                                                                type: 2,
                                                                uniqueDataSource:filterDataSource,
                                                            }
                                                        }
                                                    )
                                                }else{
                                                    this.props.dispatch(
                                                        {
                                                            type: 'TICKETS_TABLE_SEARCH',
                                                            payload: {
                                                                type: 2,
                                                                uniqueDataSource:this.props.backupCloseTicketDataSource,
                                                            }
                                                        }
                                                    )
                                                }
                                            }}
                                        />
                                        <ReactHTMLTableToExcel
                                            id="test-table-xls-button"
                                            className="btnPrimary"
                                            table="table-to-xls"
                                            filename="closedTickets"
                                            sheet="tablexls"
                                            buttonText="Download xls"
                                        />
                                    </div>
                                    <div className="clearfix"></div>

                                    <div className="marginTop10"></div>
                                    <Table
                                        ref='closedTicketsTable'
                                        columns={columns} size={'small'}
                                        loading={this.props.closedLoading}
                                        dataSource={this.props.closeTicketDataSource}
                                        onChange={(pagination, filters, sorter)=>{
                                            if (sorter.field && sorter.order) {
                                                this.props.dispatch({type:'TICKETS_TABLE_SORT',
                                                    payload: {
                                                        sortKey: sorter.field,
                                                        sortOrder: sorter.order,
                                                        type:2,
                                                        uniqueDataSource:this.props.closeTicketDataSource
                                                    }
                                                });

                                            }
                                        }}
                                    />

                                </TabPane>
                                <TabPane tab="Open Quotes" key="4">
                                    <div className="pull-right">    <Input
                                        placeholder="Search..."
                                        style={{width: 200, float: 'right'}}
                                        size="large"
                                        onPressEnter={(e)=>{
                                            let filterKey = e.target.value.toLowerCase() || '';
                                            if (filterKey) {
                                                let filterDataSource = this.props.backupOpenQuotesDataSource.filter(function (row) {
                                                    return Object.keys(row).some(function (key) {
                                                        return String(row[key]).toLowerCase().indexOf(filterKey) > -1
                                                    })
                                                });
                                                this.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_SEARCH',
                                                        payload: {
                                                            type: 3,
                                                            uniqueDataSource:filterDataSource,
                                                        }
                                                    }
                                                )
                                            }else{
                                                this.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_SEARCH',
                                                        payload: {
                                                            type: 3,
                                                            uniqueDataSource:this.props.backupOpenQuotesDataSource,
                                                        }
                                                    }
                                                )
                                            }
                                        }}
                                    /></div>
                                    <div className="clearfix"></div>

                                    <div className="marginTop10"></div>
                                    <Table columns={openQuoteColumns} size={'small'}
                                           loading={this.props.openQuotesLoading}
                                           dataSource={this.props.openQuotesDataSource}
                                           onChange={(pagination, filters, sorter)=>{
                                               if (sorter.field && sorter.order) {
                                                   this.props.dispatch({type:'TICKETS_TABLE_SORT',
                                                       payload: {
                                                           sortKey: sorter.field,
                                                           sortOrder: sorter.order,
                                                           type:3,
                                                           uniqueDataSource:this.props.openQuotesDataSource
                                                       }
                                                   });

                                               }
                                           }}

                                    />
                                </TabPane>
                                <TabPane tab="Accepted Quotes" key="5">
                                    <div className="pull-right">    <Input
                                        placeholder="Search..."
                                        style={{width: 200, float: 'right'}}
                                        size="large"
                                        onPressEnter={(e)=>{
                                            let filterKey = e.target.value.toLowerCase() || '';
                                            if (filterKey) {
                                                let filterDataSource = this.props.backupAcceptedQuotesDataSource.filter(function (row) {
                                                    return Object.keys(row).some(function (key) {
                                                        return String(row[key]).toLowerCase().indexOf(filterKey) > -1
                                                    })
                                                });
                                                this.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_SEARCH',
                                                        payload: {
                                                            type: 4,
                                                            uniqueDataSource:filterDataSource,
                                                        }
                                                    }
                                                )
                                            }else{
                                                this.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_SEARCH',
                                                        payload: {
                                                            type: 4,
                                                            uniqueDataSource:this.props.backupAcceptedQuotesDataSource,
                                                        }
                                                    }
                                                )
                                            }
                                        }}
                                    /></div>
                                    <div className="clearfix"></div>
                                    <div className="marginTop10"></div>
                                    <Table columns={QuoteColumns} size={'small'}
                                           loading={this.props.acceptedQuotesLoading}
                                           dataSource={this.props.acceptedQuotesDataSource}
                                           onChange={(pagination, filters, sorter)=>{
                                               if (sorter.field && sorter.order) {
                                                   this.props.dispatch({type:'TICKETS_TABLE_SORT',
                                                       payload: {
                                                           sortKey: sorter.field,
                                                           sortOrder: sorter.order,
                                                           type:4,
                                                           uniqueDataSource:this.props.acceptedQuotesDataSource
                                                       }
                                                   });

                                               }
                                           }}
                                    />
                                </TabPane>
                                <TabPane tab="Closed Quotes" key="6">
                                    <div className="pull-right">    <Input
                                        placeholder="Search..."
                                        style={{width: 200, float: 'right'}}
                                        size="large"
                                        onPressEnter={(e)=>{
                                            let filterKey = e.target.value.toLowerCase() || '';
                                            if (filterKey) {
                                                let filterDataSource = this.props.backupClosedQuotesDataSource.filter(function (row) {
                                                    return Object.keys(row).some(function (key) {
                                                        return String(row[key]).toLowerCase().indexOf(filterKey) > -1
                                                    })
                                                });
                                                this.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_SEARCH',
                                                        payload: {
                                                            type: 5,
                                                            uniqueDataSource:filterDataSource,
                                                        }
                                                    }
                                                )
                                            }else{
                                                this.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_SEARCH',
                                                        payload: {
                                                            type: 5,
                                                            uniqueDataSource:this.props.backupClosedQuotesDataSource,
                                                        }
                                                    }
                                                )
                                            }
                                        }}
                                    /></div>
                                    <div className="clearfix"></div>
                                    <div className="marginTop10"></div>
                                    <Table columns={QuoteColumns} size={'small'}
                                           loading={this.props.closedQuotesLoading}
                                           dataSource={this.props.closedQuotesDataSource}
                                           onChange={(pagination, filters, sorter)=>{
                                               if (sorter.field && sorter.order) {
                                                   this.props.dispatch({type:'TICKETS_TABLE_SORT',
                                                       payload: {
                                                           sortKey: sorter.field,
                                                           sortOrder: sorter.order,
                                                           type:5,
                                                           uniqueDataSource:this.props.closedQuotesDataSource
                                                       }
                                                   });

                                               }
                                           }}
                                    />
                                </TabPane>
                                <TabPane tab="Users/Computers" key="7">
                                    <div className="marginTop10">
                                        <h4 style={{marginLeft:5}}>List of users/computers on your Licence Agreement:</h4>
                                    </div>
                                    <div className="pull-right">
                                        <Input
                                        placeholder="Search..."
                                        style={{width: 200,marginRight:7}}
                                        size="large"
                                        onPressEnter={(e)=>{
                                            let filterKey = e.target.value.toLowerCase() || '';
                                            if (filterKey) {
                                                let filterDataSource = this.props.backupUsersDataSource.filter(function (row) {
                                                    return Object.keys(row).some(function (key) {
                                                        return String(row[key]).toLowerCase().indexOf(filterKey) > -1
                                                    })
                                                });
                                                this.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_SEARCH',
                                                        payload: {
                                                            type: 6,
                                                            uniqueDataSource:filterDataSource,
                                                        }
                                                    }
                                                )
                                            }else{
                                                this.props.dispatch(
                                                    {
                                                        type: 'TICKETS_TABLE_SEARCH',
                                                        payload: {
                                                            type: 6,
                                                            uniqueDataSource:this.props.backupUsersDataSource,
                                                        }
                                                    }
                                                )
                                            }
                                        }}
                                    />
                                        <ReactHTMLTableToExcel
                                            id="test-table-xls-button"
                                            className="btnPrimary"
                                            table="table-to-xls"
                                            filename="usersComputers"
                                            sheet="tablexls"
                                            buttonText="Download Csv"/>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="marginTop10"></div>
                                    <Table
                                        ref='usersComputersTable'
                                         columns={userColumns} size={'small'}
                                           loading={this.props.userLoading}
                                           dataSource={this.props.usersDataSource}
                                        pagination={{ showSizeChanger:true,pageSizeOptions: ['10','20','50','200']} }
                                           onChange={(pagination, filters, sorter)=>{
                                               if (sorter.field && sorter.order) {
                                                   this.props.dispatch({type:'TICKETS_TABLE_SORT',
                                                       payload: {

                                                           sortKey: sorter.field,
                                                           sortOrder: sorter.order,
                                                           type:6,
                                                           uniqueDataSource:this.props.usersDataSource
                                                       }
                                                   });

                                               }
                                           }}
                                    />
                                </TabPane>
                            </Tabs>
                            <Modal
                                title="Ticket Details"
                                width={"75%"}
                                visible={this.state.isTaskModal}
                                onCancel={() => {
                                    this.handleCloseTasksData()
                                }}
                                footer={[
                                    <Button
                                    key={"OK"}
                                    onClick={() => {
                                        this.handleCloseTasksData()
                                    }}
                                    type={"primary"}
                                    >
                                    Close
                                    </Button>
                                ]}
                            >
                                <Row>
                                    <Col>
                                        <div className="lblWrapper">
                                            <label>Ticket ID</label>
                                            <label>{this.state.openTicketDetails.id}</label>
                                        </div>
                                        <div className="lblWrapper">
                                            <label>Status</label>
                                            <label>{this.state.openTicketDetails.statusD}</label>
                                        </div>
                                        <div className="lblWrapper">
                                            <label>Type</label>
                                            <label>{this.state.openTicketDetails.classification}</label>
                                        </div>
                                        <div className="lblWrapper">
                                            <label>Created</label>
                                            <label>{this.state.openTicketDetails.created}</label>
                                        </div>
                                        <div className="lblWrapper">
                                            <label>Age</label>
                                            <label>{this.state.openTicketDetails.ownerAge}</label>
                                        </div>
                                        <div className="lblWrapper">
                                            <label>Last Action</label>
                                            <label>{this.state.openTicketDetails.last_action}</label>
                                        </div>
                                        <div className="lblWrapper">
                                            <label>Subject</label>
                                            <label>{this.state.openTicketDetails.subDes}</label>
                                        </div>
                                        <div className="lblWrapper">
                                            <label>Description</label>
                                            <label>
                                                <div
                                                dangerouslySetInnerHTML={{
                                                    __html: this.state.openTicketDetails
                                                    .viewDescription,
                                                }}
                                                />
                                            </label>
                                        </div>
                                        <div className="lblWrapper">
                                            <label>Task</label>
                                            <label>
                                                <ul style={{ marginLeft: "18px" }}>
                                                    {this.state.openTicketDetails.tasks &&
                                                    this.state.openTicketDetails.tasks.map((item, i) => {
                                                        const taskD = item.map((task, tid) => {
                                                            if (task.description) {
                                                                return (
                                                                    <li key={tid}>{task.description}</li>
                                                                )
                                                            }
                                                        })
                                                        return taskD;
                                                    })}
                                                </ul>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


class DescriptionRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        }
    }

    render() {
        let description = <div dangerouslySetInnerHTML={{__html: this.props.description}}/>
        let subDes = <div dangerouslySetInnerHTML={{__html: this.props.subDes}}/>
        return (
            <div>
                {this.props.subDes}
                {this.state.visible ? <div>{description !== 'null' ? description : ''}</div> : ''}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        confirmTicketDataSource: state.ticketsQuotesState.confirmTicketDataSource,
        openTicketDataSource: state.ticketsQuotesState.openTicketDataSource,
        closeTicketDataSource: state.ticketsQuotesState.closeTicketDataSource,
        openQuotesDataSource: state.ticketsQuotesState.openQuotesDataSource,
        acceptedQuotesDataSource: state.ticketsQuotesState.acceptedQuotesDataSource,
        closedQuotesDataSource: state.ticketsQuotesState.closedQuotesDataSource,
        usersDataSource: state.ticketsQuotesState.usersDataSource,
        confirmLoading: state.ticketsQuotesState.confirmLoading,
        openingLoading: state.ticketsQuotesState.openingLoading,
        closedLoading: state.ticketsQuotesState.closedLoading,
        openQuotesLoading: state.ticketsQuotesState.openQuotesLoading,
        acceptedQuotesLoading: state.ticketsQuotesState.acceptedQuotesLoading,
        closedQuotesLoading: state.ticketsQuotesState.closedQuotesLoading,
        userLoading: state.ticketsQuotesState.userLoading,

        backupConfirmTicketDataSource: state.ticketsQuotesState.backupConfirmTicketDataSource,
        backupOpenTicketDataSource: state.ticketsQuotesState.backupOpenTicketDataSource,
        backupCloseTicketDataSource: state.ticketsQuotesState.backupCloseTicketDataSource,
        backupOpenQuotesDataSource: state.ticketsQuotesState.backupOpenQuotesDataSource,
        backupAcceptedQuotesDataSource: state.ticketsQuotesState.backupAcceptedQuotesDataSource,
        backupClosedQuotesDataSource: state.ticketsQuotesState.backupClosedQuotesDataSource,
        backupUsersDataSource: state.ticketsQuotesState.backupUsersDataSource,

        clientDetails: state.createJobState.clientDetails

    };}

export default connect(mapStateToProps)(withRouter(TicketQuotes));
