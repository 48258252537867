import React from "react";
import {Select, Input, Button, message} from 'antd';
import request from '../../utils/request';
import {withRouter } from 'react-router-dom'
import GlobalParameters from '../../utils/globalParameters';
import {connect} from "react-redux";
import '../../scss/mixin.css'
import queryString from 'query-string'


const Option = Select.Option;
let token=null;

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            securityQuestionsDataSource: [],
            question1: null,
            question1Value: null,
            answer1: null,
        };
    };


    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        token = values.token;

        request(`${GlobalParameters.website}/erams/api/security/list`, {method: 'post'}, token, 3).then((response) => {
            if (response.data[0] === 'success') {
                let securityQuestionsDataSource = [];
                const item = {};
                item.id = '' + response.data[1][0];
                item.question = response.data[1][1];
                securityQuestionsDataSource.push(item);
                this.setState({securityQuestionsDataSource, question1: item.id});
            }
        });
    }


    render() {
        let securityQuestionsOptions = this.state.securityQuestionsDataSource.map(d => <Option key={'' + d.id}
                                                                                               title={'' + d.question}
                                                                                               value={'' + d.id}>{d.question}</Option>);
        return (
            <div>
                <div className="marginTop">
                </div>
                < div
                    className="container">

                    < div
                        className="panel panel-success">
                        < div
                            className="panel-heading">
                            < h3 className="panel-title"> Setting Security Questions </h3>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <span style={{marginRight: 3}}>Question 1  : </span>
                                    <Select
                                        showSearch
                                        style={{width: '88%'}}
                                        value={this.state.question1}
                                        onChange={(value, option) => {
                                            this.setState({question1: value, question1Value: option.props.title});
                                        }}
                                        filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {securityQuestionsOptions}
                                    </Select>

                                </div>
                            </div>
                            <div className="marginTop5"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Input placeholder="" style={{width: '99%'}} onChange={(e) => {
                                        this.setState({answer1: e.target.value})
                                    }}/>
                                </div>
                            </div>


                            <div className="marginTop10"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Button type="primary" onClick={() => {

                                        if (!this.state.answer1) {
                                            message.error('please input an answer');
                                            return;
                                        }
                                        const values = {};
                                        values.question_id = this.state.question1;
                                        values.answer = this.state.answer1;


                                        request(`${GlobalParameters.website}/erams/api/security/validate`, {method: 'put',data:values}, token, 3).then((response) => {
                                            if (response.data[0] === 'exception') {
                                               message.error(response.data[1])

                                            }else{
                                                this.props.history.push({
                                                    pathname: '/auth/setNewPassword',
                                                    search: `?token=${response.data[1]}`
                                                })
                                                // $location.path('/setNewPassword/' + result.data[1]);
                                            }
                                        });
                                    }}>Submit</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default withRouter(ForgotPassword);
