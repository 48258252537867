import React from "react";
import {Select, Input, Button, message, Col, Row} from 'antd';
import request from '../../utils/request';
import GlobalParameters from '../../utils/globalParameters';
import {connect} from "react-redux";
import '../../scss/mixin.css'

const Option = Select.Option;

class SecurityQuestions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            securityQuestionsDataSource: [],
            question1: null,
            question2: null,
            question3: null,
            questionId1: null,
            questionId2: null,
            questionId3: null,
            question1Value: null,
            question2Value: null,
            question3Value: null,
            answer1: '',
            answer2: '',
            answer3: '',
            change1: null,
            change2: null,
            change3: null,
            change4:null,
            answerVisible: false,
            verbal_password:null,
            sucVisible:false,
        };
    };


    componentDidMount() {
        // let token = sessionStorage.getItem("token");
        // request(`${GlobalParameters.website}/erams/api/security/list`, {method: 'put'}).then((response) => {
        //     let resData = response.data[1];
        //     resData = resData[0].data;
        //     let securityQuestionsDataSource = [];
        //     for (var i = 0; i < resData.length; i++) {
        //         var temp = new Object();
        //         temp.id = ''+resData[i].id;
        //         temp.question = resData[i].attributes.question;
        //         temp.answer = resData[i].attributes.answer;
        //         securityQuestionsDataSource.push(temp);
        //
        //     }
        //     console.info(securityQuestionsDataSource)
        //     this.setState({
        //         securityQuestionsDataSource
        //         , question1: securityQuestionsDataSource[0].id
        //         , question2: securityQuestionsDataSource[1].id
        //         , question3: securityQuestionsDataSource[2].id
        //         ,question1Value: securityQuestionsDataSource[0].question
        //         ,question2Value: securityQuestionsDataSource[1].question
        //         ,question3Value: securityQuestionsDataSource[2].question
        //     })
        // });

        request(`${GlobalParameters.website}/erams/api/cportal/security`, {method: 'put'}).then((response) => {
            let resData = response.data[1];
            let resAnswer = resData[1];
            resData = resData[0].data;
            let securityQuestionsDataSource = [];
            for (var i = 0; i < resData.length; i++) {
                var temp = new Object();
                temp.id = '' + resData[i].id;
                temp.question = resData[i].attributes.question;
                temp.answer = resData[i].attributes.answer;
                securityQuestionsDataSource.push(temp);

            }
            if(response.data[1][2]){
                this.setState({
                    verbal_password: response.data[1][2].verbal_password})
            }

            if (resAnswer.length > 0) {
                this.setState({
                    securityQuestionsDataSource
                    , question1: '' + resAnswer[0][1]
                    , question2: '' + resAnswer[1][1]
                    , question3: '' + resAnswer[2][1],
                    questionId1: '' + resAnswer[0][0],
                    questionId2: '' + resAnswer[1][0],
                    questionId3: '' + resAnswer[2][0]
                    , question1Value: resAnswer[0][1]
                    , question2Value: resAnswer[1][1]
                    , question3Value: resAnswer[2][1]
                    , answer1: '' + resAnswer[0][2],
                    answer2: '' + resAnswer[1][2],
                    answer3: '' + resAnswer[2][2],
                    answerVisible: true

                })
            } else {
                this.setState({
                    securityQuestionsDataSource
                    , question1: securityQuestionsDataSource[0].question
                    , question2: securityQuestionsDataSource[1].question
                    , question3: securityQuestionsDataSource[2].question
                    , question1Value: securityQuestionsDataSource[0].question
                    , question2Value: securityQuestionsDataSource[1].question
                    , question3Value: securityQuestionsDataSource[2].question,
                    answer1: '',
                    answer2: '',
                    answer3: '',
                })
            }

        });
    }


    render() {
        let securityQuestionsOptions = this.state.securityQuestionsDataSource.map(d => <Option key={'' + d.id}
                                                                                               title={'' + d.question}
                                                                                               value={'' + d.id}>{d.question}</Option>);
        return (
            <div>
                <div className="marginTop">
                </div>
                < div
                    className="container">

                    < div
                        className="panel panel-success">
                        < div
                            className="panel-heading">
                            < h3 className="panel-title">Security Questions</h3>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <h4>
                                        As part of our commitment to our client's privacy and security we can sometimes request the answer to security questions to help with authenticating and authorising work.
                                    </h4>
                                    <h5>
                                        When speaking on the phone with us we may ask you for a verbal password.
                                    </h5>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Row>
                                        <Col span={4} style={{marginTop: '3px'}}>
                                            <span>Verbal Password :</span>
                                        </Col>
                                        <Col span={20}>
                                            <Input value={this.state.verbal_password}
                                                   onChange={(e) => {
                                                       this.setState({verbal_password: e.target.value,change4:'4'})
                                                   }}/>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                                <div className="row">
                                    <div className="col-md-8 col-md-offset-2">
                                        <h5>The following question and answers are currently recorded for your account：</h5>
                                        {this.state.answerVisible ? "" :
                                            <p>There are no security questions set, please use the following form to set
                                                them:</p>}
                                    </div>
                                </div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Row>
                                        <Col span={4}  style={{marginTop: '3px'}}>
                                            <span>Question 1 :</span>
                                        </Col>
                                        <Col span={20}>
                                            <Select
                                                showSearch
                                                style={{width: '100%'}}
                                                value={this.state.question1}
                                                onChange={(value, option) => {
                                                    this.setState({
                                                        question1: value,
                                                        question1Value: option.props.title,
                                                        change1: '1'
                                                    });
                                                }}
                                                filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {securityQuestionsOptions}
                                            </Select>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="marginTop5"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Row>
                                        <Col span={20} offset={4}>
                                            <Input value={this.state.answer1}
                                                   onChange={(e) => {
                                                       this.setState({answer1: e.target.value, change1: '1'})
                                                   }}/>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="marginTop10"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Row>
                                        <Col span={4}  style={{marginTop: '3px'}}>
                                            <span>Question 2  :</span>
                                        </Col>
                                        <Col span={20}>
                                            <Select
                                                showSearch
                                                style={{width: '100%'}}
                                                value={this.state.question2}
                                                onChange={(value, option) => {
                                                    this.setState({
                                                        question2: value,
                                                        question2Value: option.props.title,
                                                        change2: '2'
                                                    });
                                                }}
                                                filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {securityQuestionsOptions}
                                            </Select>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="marginTop5"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Row>
                                        <Col span={20} offset={4}>
                                            <Input value={this.state.answer2}
                                                   onChange={(e) => {
                                                       this.setState({answer2: e.target.value, change2: '2'})
                                                   }}/>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="marginTop10"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Row>
                                        <Col span={4}  style={{marginTop: '3px'}}>
                                            <span>Question 3  :</span>
                                        </Col>
                                        <Col span={20}>
                                            <Select
                                                showSearch
                                                style={{width: '100%'}}
                                                value={this.state.question3}
                                                onChange={(value, option) => {
                                                    this.setState({
                                                        question3: value,
                                                        question3Value: option.props.title,
                                                        change3: '3'
                                                    });
                                                }}
                                                filterOption={(input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {securityQuestionsOptions}
                                            </Select>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="marginTop5"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <Row>
                                        <Col span={20} offset={4}>
                                            <Input value={this.state.answer3}
                                                   onChange={(e) => {
                                                       this.setState({answer3: e.target.value, change3: '3'})
                                                   }}/>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="marginTop10"></div>
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <div>
                                    {this.state.sucVisible?'The security questions have been updated':''}</div>
                                    <div>{this.state.sucVisible?<a className="email-forgot" onClick={()=>{
                                        this.props.history.push('/client');
                                    }}>Return To Tickets & Quotes page Page</a>:''}</div>

                                    <Button type="primary" onClick={() => {
                                        if (this.state.question1 == this.state.question2 || this.state.question2 == this.state.question3 || this.state.question3 == this.state.question1) {
                                            message.error('Repeating questions');
                                            return;
                                        }
                                        if (!this.state.answerVisible) {
                                            if (this.state.answer1 == this.state.answer2 || this.state.answer2 == this.state.answer3 || this.state.answer3 == this.state.answer1) {
                                                message.error('Repeating answers');
                                                return;
                                            }
                                        } else {
                                            if (!this.state.answer1 || !this.state.answer2 || !this.state.answer3) {
                                                message.error('Please input your answers');
                                                return;
                                            }
                                        }

                                        const values = {};
                                        values.no_change = [];
                                        values.no_token = 1;
                                        // values.question = [this.state.question1Value, this.state.question2Value, this.state.question3Value];
                                        values.question = [];
                                        values.answer = [];
                                        values.verbal_password=this.state.verbal_password;


                                        if (!this.state.change1) {
                                            values.no_change.push(this.state.questionId1)
                                        } else {
                                            values.question.push(this.state.question1Value)
                                            values.answer.push(this.state.answer1)
                                        }

                                        if (!this.state.change2) {
                                            values.no_change.push(this.state.questionId2)
                                        } else {
                                            values.question.push(this.state.question2Value)
                                            values.answer.push(this.state.answer2)
                                        }

                                        if (!this.state.change3) {
                                            values.no_change.push(this.state.questionId3)
                                        } else {
                                            values.question.push(this.state.question3Value)
                                            values.answer.push(this.state.answer3)
                                        }

                                        if(values.question.length===0 && !this.state.change4){
                                            message.success('The security questions have been updated');
                                            this.setState({sucVisible:true});
                                            return;
                                        }

                                        request(`${GlobalParameters.website}/erams/api/cportal/answer`, {
                                            method: 'PUT',
                                            data: values
                                        }).then((response) => {
                                            if (response.data[0] === 'exception') {
                                                message.error(response.data[1]);
                                            } else {
                                                message.success('The security questions have been updated')
                                                this.setState({sucVisible:true});
                                                // this.setState({change1: null, change2: null, change3: null});
                                                // setTimeout(()=>{
                                                //     window.location.href=`${GlobalParameters.webserver}/client/secureReset`
                                                // }, 2000);

                                            }
                                        });
                                    }}>Submit</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        clientId: state.loggedUserState.clientId,
        token: state.loggedUserState.token
    }
}
export default connect(mapStateToProps)(SecurityQuestions);
