import {takeLatest, call, put} from 'redux-saga/effects';
import * as ticketsService from '../api/ticketsQuotesService';


export function* ticketsQuotesQuery(action) {
    const {data} = yield call(ticketsService.ticketsQuotesQuery, action.customer_id);
    yield put({type: "CONFIRMED_QUERY", data});
}
export function* openTicketsQuotesQuery(action) {
    const {data} = yield call(ticketsService.openTicketsQuotesQuery, action.customer_id);
    yield put({type: "OPEN_QUERY", data});
}

export function* closeTicketsQuotesQuery(action) {
    const {data} = yield call(ticketsService.closeTicketsQuotesQuery, action.customer_id);
    yield put({type: "CLOSE_QUERY", data});
}
export function* openQuotesQuery(action) {
    const {data} = yield call(ticketsService.openQuotesQuery, action.customer_id);
    yield put({type: "OPEN_QUOTE_QUERY", data});
}
export function* acceptedQuotesQuery(action) {
    const {data} = yield call(ticketsService.acceptedQuotesQuery, action.customer_id);
    yield put({type: "ACCEPTED_QUOTE_QUERY", data});
}
export function* closedQuotesQuery(action) {
    const {data} = yield call(ticketsService.closedQuotesQuery, action.customer_id);
    yield put({type: "CLOSED_QUOTE_QUERY", data});
}
export function* usersQuery(action) {
    const {data} = yield call(ticketsService.usersQuery, action.customer_id);
    yield put({type: "USER_QUERY", data});
}
export function* authOfficerQuery(action) {
    const {data} = yield call(ticketsService.authOfficerQuery, action.customer_id);
    yield put({type: "AUTHOFFICER_QUERY", data});
}
