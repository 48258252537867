import {takeLatest, call, put} from 'redux-saga/effects';
import * as createJobService from '../api/createJobService';

export function* getCategoryList() {
    const {data} = yield call(createJobService.getCategoryList);
    yield put({type: "GET_CATEGORY_LIST", data});
}

export function* getSelectedClient(action) {
    yield put({type: "GET_SELECTED_CLIENT", action})
}
