const initialState = {
    categoryList: [],
    clientDetails: {}
};

const createJobReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CATEGORY_LIST':
            console.log(action, 'action from reducer');
            return {
                ...state,
                categoryList: action.data
            };

        case 'GET_SELECTED_CLIENT':
            return{
                ...state,
                clientDetails: action.clientDetails
            }
    }
    return state;
};

export default createJobReducer
